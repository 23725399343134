import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Tooltip from '@mui/material/Tooltip';
import { TextField, MenuItem, FormControl, InputLabel, Select, RadioGroup, FormControlLabel, Radio, Button, Typography, Box, Paper, FormHelperText, Alert } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { micaOrderCreate, micaServiceCreate, getSimData } from "../../actions/tdmOpsActions";
import Grid from '@mui/material/Grid';
import DownloadButton from './DownloadButton';
import moment from 'moment';

import {
    Stepper,
    Step,
    StepLabel,
    CircularProgress,
    Avatar
} from '@mui/material';

const MicaForm = ({ selectedEnvValue, micaOrderCreate, micaServiceCreate, getSimData, teamID, dNumber }) => {
    const [inputSelection, setInputSelection] = useState('Input Data');
    const [msisdn, setMsisdn] = useState('');
    const [imsi, setImsi] = useState('');
    const [sim, setSim] = useState('');
    const [productId] = useState('1');
    const [plan, setPlan] = useState('');

    const [customType, setCustomPlan] = useState('SOLE');
    const [cpoProductCode, setCpoProductCode] = useState('CNOBONUS');
    const [flexiPlanProductCode, setFlexiPlanProductCode] = useState('GSM');
    const [networkGroup, setNetworkGroup] = useState('G+C');

    const [radioValue, setRadioValue] = useState('');

    const [deviceList, setDeviceList] = useState('');
    const [tacCode, setTacCode] = useState('');

    const [errors, setErrors] = useState({});


    const [trackArr, setTrackArr] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [accountNum, setAccountNum] = useState('');
    const [micaCustomer, setMicaCustomer] = useState({});
    const [showResetBtn, setShowResetBtn] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const cpoProductCodeOptions = [
        { label: 'CNOBONUS' },
        { label: 'CASUAL' },
        { label: 'MRNOBONU' }
    ]

    const flexiPlanProductCodeOptions = [
        { label: 'AAPT' },
        { label: 'AMPS' },
        { label: 'CDMA' },
        { label: 'GCDM' },
        { label: 'GEM' },
        { label: 'GSM' },
        { label: 'HCDM' },
        { label: 'IGSM' },
        { label: 'LANE' },
        { label: 'MD' },
        { label: 'NGSM' },
        { label: 'OGSM' },
        { label: 'PCS' },
        { label: 'PSTN' },
        { label: 'RES' },
        { label: 'SAT' },
        { label: 'VGSM' },
        { label: 'WLL' },
    ]

    const accNetworkGroupOptions = [
        { label: 'G+C' },
        { label: 'MD' },
        { label: 'SAT' },
    ]

    const resetStateValue = () => {
        setInputSelection('Input Data');
        setMsisdn('')
        setImsi('')
        setSim('')
        setPlan('')
        setCustomPlan('')
        setRadioValue('Plan With Device')
        setDeviceList('')
        setTacCode('')
        setErrors({})
        setTrackArr([])
        setActiveStep(0)
        setLoading(false);
        setAccountNum(null);
        setMicaCustomer(null);
        setErrorMsg('');
        setShowResetBtn(false);
    }


    const handleInputSelectionChange = (event) => {
        if (inputSelection === "TDM Data") {
            resetStateValue();
        }
        setInputSelection(event.target.value);
    };

    const handlePlanChange = (value) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            plan: !value ? "Plan is required" : "",
        }));
        setPlan(value);
    };

    const handleCustomPlanChange = (value) => {
        setCustomPlan(value);
    };

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
        if (event.target.value === "Plan Only") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                deviceList: "",
                tacCode: "",
            }));
        }
    };

    const handleDeviceListChange = (value) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            deviceList: !value ? "Device Prodct Code is required" : "",
        }));
        setDeviceList(value);
    };

    const handleTacCodeChange = (event) => {
        let value = event.target.value;
        setErrors((prevErrors) => ({
            ...prevErrors,
            tacCode: validateTacCode(value),
        }));
        setTacCode(event.target.value);
    };

    const handlecpoProductCode = (value) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            cpoProductCode: !value ? "CPO Product Code is required" : "",
        }));
        setCpoProductCode(value);
    };

    const handleFlexiPlanProductCodeOptions = (value) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            flexiPlanProductCode: !value ? "Flexi Plan Product Code is required" : "",
        }));
        setFlexiPlanProductCode(value);
    };

    const handleAccNetworkGroupOptions = (value) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            networkGroup: !value ? "Network Group is required" : "",
        }));
        setNetworkGroup(value);
    };

    const validateMsisdn = (value) => {
        if (!value) return "MSISDN is required";
        if (!/^61/.test(value)) return "MSISDN must start with 61";
        if (!/^\d{11}$/.test(value)) return "MSISDN must be 11 digits";
        return "";
    };

    const validateImsi = (value) => {
        if (!value) return "IMSI is required";
        if (!/^\d{15}$/.test(value)) return "IMSI must be 15 digits";
        return "";
    };

    const validateSim = (value) => {
        if (!value) return "SIM is required";
        if (!/^\d{20}$/.test(value)) return "SIM must be 20 digits";
        return "";
    };

    const validateTacCode = (value) => {
        if (!value) return "TAC Code is required";
        if (!/^\d{8}$/.test(value)) return "TAC Code must be 8 digits";
        return "";
    };

    const handleMsisdnChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setMsisdn(value);
            setErrors((prevErrors) => ({
                ...prevErrors,
                msisdn: validateMsisdn(value),
            }));
        }
    };

    const handleImsiChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setImsi(value);
            setErrors((prevErrors) => ({
                ...prevErrors,
                imsi: validateImsi(value),
            }));
        }
    };

    const handleSimChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setSim(value);
            setErrors((prevErrors) => ({
                ...prevErrors,
                sim: validateSim(value),
            }));
        }
    };

    const validate = () => {
        let tempErrors = {};
        if (inputSelection === 'Input Data') {
            tempErrors.msisdn = validateMsisdn(msisdn);
            tempErrors.imsi = !imsi ? "IMSI is required" : errors.imsi;
            tempErrors.sim = !sim ? "SIM is required" : errors.sim;
        }
        if (radioValue === 'Plan With Device') {
            tempErrors.plan = !plan ? "Plan is required" : "";
            tempErrors.deviceList = !deviceList ? "Device Prodct Code is required" : "";
            tempErrors.tacCode = !tacCode ? "TAC code is required" : "";
        }
        if (radioValue === 'Plan Only') {
            tempErrors.plan = !plan ? "Plan is required" : "";
        }
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const msisdnConvert = (str) => {
        let remove_61 = str.slice(2);

        let add_first_char = `0${remove_61}`;
        return add_first_char;
    }

    const trackStatusArrBuild = (inputSelection, radioValue) => {
        const scenario1 = ['Account Creation', 'Product Creation'];
        const scenario2 = ['Account Creation (Plan Only)'];
        const scenario3 = ['Fetching TDM Data', 'Account Creation', 'Product Creation'];
        const scenario4 = ['Fetching TDM Data', 'Account Creation (Plan Only)'];
        if (inputSelection === "TDM Data") {
            if (radioValue === 'Plan With Device') {
                return scenario3;
            } else {
                return scenario4;
            }
        } else {
            if (radioValue === 'Plan With Device') {
                return scenario1;
            } else {
                return scenario2;
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validate()) {
            let trackStatusArr = trackStatusArrBuild(inputSelection, radioValue);
            setTrackArr(trackStatusArr);
            if (inputSelection === "TDM Data") {
                let payload = {
                    dNumber,
                    "team": teamID,
                    "comment": "",
                    "quantity": 1,
                    "environment": selectedEnvValue,
                    "scope": "TEAM"
                }
                setLoading(true);
                getSimData(payload).then((result) => {
                    setMsisdn(result?.msisdn);
                    setImsi(result?.imsi);
                    setSim(result?.sim);
                    setActiveStep(1)
                    result.msisdn = msisdnConvert(result?.msisdn)
                    let payload2 = {
                        "micaOrderRequests": [
                            {
                                ...result,
                                "productId": 1,
                                "environment": selectedEnvValue,
                                plan,
                                "custType": customType,
                                cpoProductCode,
                                flexiPlanProductCode,
                                accNtwrGroup: networkGroup,
                                "isDdc": false
                            }
                        ]
                    }
                    micaOrderCreate(payload2).then((result2) => {
                        setActiveStep(2);
                        let { micaOrderCreationDetails } = result2.data;
                        let { accountNumber, micaCustomer, status, message } = micaOrderCreationDetails[0];
                        if (status === "FAIL") {
                            setLoading(false);
                            setErrorMsg(message);
                            setShowResetBtn(true);
                        } else {
                            if (!accountNumber) {
                                setLoading(false);
                                setErrorMsg(message);
                                return setShowResetBtn(true);
                            }
                            setMicaCustomer(micaCustomer);
                            setAccountNum(accountNumber);
                            if (radioValue === 'Plan With Device') {
                                let payload3 = {
                                    "productCode": {
                                        "deviceProductCodes": [
                                            {
                                                "code": deviceList,
                                                "tac": tacCode
                                            }
                                        ]
                                    },
                                    "env": selectedEnvValue,
                                    "msisdn": result.msisdn,
                                    accountNumber
                                }
                                micaServiceCreate(payload3).then((result3) => {
                                    setActiveStep(3);
                                    setLoading(false);
                                    setShowResetBtn(true);
                                }).catch(() => {
                                    setShowResetBtn(true);
                                    setLoading(false);
                                    setErrorMsg("Error occured during MICA Service Creation")
                                });
                            } else {
                                setLoading(false);
                                setShowResetBtn(true);
                            }
                        }
                    }).catch(() => {
                        setLoading(false);
                        setErrorMsg("Error occured during MICA Order Creation");
                        setShowResetBtn(true);
                    })
                })
                    .catch(() => {
                        setLoading(false);
                        setErrorMsg("Error occured during geting these Assets: Msisdn,IMSI,SIM ");
                        setShowResetBtn(true);
                    });
            } else {
                setLoading(true);
                let msisdnConvertStr = msisdnConvert(msisdn);
                let payload = {
                    "micaOrderRequests": [
                        {
                            msisdn: msisdnConvertStr,
                            imsi,
                            sim,
                            "productId": 1,
                            "environment": selectedEnvValue,
                            plan,
                            "custType": customType,
                            cpoProductCode,
                            flexiPlanProductCode,
                            accNtwrGroup: networkGroup,
                            "isDdc": false
                        }
                    ]
                }
                micaOrderCreate(payload).then((result) => {
                    let { micaOrderCreationDetails } = result.data;
                    let { accountNumber, micaCustomer, status, message } = micaOrderCreationDetails[0];
                    if (status === "FAIL") {
                        setLoading(false);
                        setErrorMsg(message);
                        setShowResetBtn(true);
                    } else {
                        if (!accountNumber) {
                            setLoading(false);
                            setErrorMsg(message);
                            return setShowResetBtn(true);
                        }
                        setAccountNum(accountNumber);
                        setMicaCustomer(micaCustomer);
                        setActiveStep(1)
                        if (radioValue === 'Plan With Device') {
                            let payload2 = {
                                "productCode": {
                                    "deviceProductCodes": [
                                        {
                                            "code": deviceList,
                                            "tac": tacCode
                                        }
                                    ]
                                },
                                "env": selectedEnvValue,
                                "msisdn": msisdnConvertStr,
                                accountNumber
                            }
                            micaServiceCreate(payload2).then((result2) => {
                                setActiveStep(2);
                                setLoading(false);
                                setShowResetBtn(true);
                            }).catch(() => {
                                setShowResetBtn(true);
                                setLoading(false);
                                setErrorMsg("Error occured during MICA Service Creation")
                            });
                        } else {
                            setLoading(false);
                            setShowResetBtn(true);

                        }
                    }
                }).catch(() => {
                    setShowResetBtn(true);
                    setLoading(false);
                    setErrorMsg("Error occured during MICA Order Creation")
                })
            }
        }
    }

    const options = [
        { label: 'SBMH*STR' }
    ];
    const options2 = [
        { label: 'SP24M-41' }
    ]
    const options3 = [
        { label: 'SOLE' },
        { label: 'PRIV' },
        { label: 'PART' },
    ]

    const mobileEntity = {
        msisdn,
        imsi,
        sim,
        customType
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    return (
        <>
            <Paper elevation={3} style={{ padding: '20px', maxWidth: '900px', margin: 'auto' }}>
                <Typography variant="h4" gutterBottom>MICA Active Order Creation</Typography>
                <form onSubmit={handleSubmit} noValidate disabled={trackArr.length > 0}>
                    <FormControl component="fieldset" margin="normal">
                        <Typography variant="h6" gutterBottom>Choose your Data Selection</Typography>
                        <RadioGroup value={inputSelection} onChange={handleInputSelectionChange} row>
                            <Tooltip title="You need to input data manually (MSISDN, IMSI, SIM)">
                                <FormControlLabel value="Input Data" control={<Radio />} label="Input Data" style={{ marginRight: "20px" }} />
                            </Tooltip>
                            <Tooltip title="TDM will automatically fetch the (MSISDN, IMSI, SIM) data.">
                                <FormControlLabel value="TDM Data" control={<Radio />} label="TDM Data" style={{ marginRight: "20px" }} />
                            </Tooltip>
                        </RadioGroup>
                    </FormControl>
                    {inputSelection === 'Input Data' && <>
                        <TextField
                            required
                            label="MSISDN"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={msisdn}
                            onChange={handleMsisdnChange}
                            onBlur={handleMsisdnChange}
                            error={!!errors.msisdn}
                            helperText={errors.msisdn}
                        />
                        <TextField
                            required
                            label="IMSI"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={imsi}
                            onChange={handleImsiChange}
                            onBlur={handleImsiChange}
                            error={!!errors.imsi}
                            helperText={errors.imsi}
                        />
                        <TextField
                            required
                            label="SIM"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={sim}
                            onChange={handleSimChange}
                            onBlur={handleSimChange}
                            error={!!errors.sim}
                            helperText={errors.sim}
                        />
                    </>}
                    <TextField
                        label="product ID"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="number"
                        value={productId}
                        InputProps={{ readOnly: true }}
                    /> <br /><br />
                    <Autocomplete
                        freeSolo
                        options={options3.map((option) => option.label)}
                        value={options3[0]?.label}
                        onChange={(event, newValue) => handleCustomPlanChange(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Customer Type"
                                variant="outlined"
                                onChange={(event, newValue) => handleCustomPlanChange(newValue)}
                                error={!!errors.customType}
                                required
                            />
                        )}
                        label="Customer Type"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <FormControl component="fieldset" margin="normal">
                        <Typography variant="h6" gutterBottom>Choose your plan</Typography>
                        <RadioGroup value={radioValue} onChange={handleRadioChange} row>
                            <FormControlLabel value="Plan With Device" control={<Radio />} label="Plan With Device" />
                            <FormControlLabel value="Plan Only" control={<Radio />} label="Plan Only" />
                            <FormControlLabel value="Plan with Device DUP" control={<Radio />} label="Plan with Device DUP" />
                        </RadioGroup>
                    </FormControl>

                    {radioValue === 'Plan With Device' && (
                        <>
                            <FormControl fullWidth margin="normal" error={!!errors.plan}>
                                <InputLabel>CPO Product Code Options</InputLabel>
                                <Autocomplete
                                    freeSolo
                                    options={cpoProductCodeOptions.map((option) => option.label)}
                                    value={cpoProductCode}
                                    onChange={(event, newValue) => handlecpoProductCode(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label="Select an CPO Product Code"
                                            variant="outlined"
                                            onChange={(event) => handlecpoProductCode(event.target.value)}
                                            error={!!errors.cpoProductCode}
                                        />
                                    )}
                                />
                                <FormHelperText>{errors.cpoProductCode}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin="normal" error={!!errors.flexiPlanProductCode}>
                                <InputLabel>Flexi Plan Product Code</InputLabel>
                                <Autocomplete
                                    freeSolo
                                    options={flexiPlanProductCodeOptions.map((option) => option.label)}
                                    value={flexiPlanProductCode}
                                    onChange={(event, newValue) => handleFlexiPlanProductCodeOptions(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label="Select an Flexi Plan Product Code"
                                            variant="outlined"
                                            onChange={(event) => handleFlexiPlanProductCodeOptions(event.target.value)}
                                            error={!!errors.flexiPlanProductCode}
                                        />
                                    )}
                                />
                                <FormHelperText>{errors.flexiPlanProductCode}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin="normal" error={!!errors.networkGroup}>
                                <InputLabel>Network Group</InputLabel>
                                <Autocomplete
                                    freeSolo
                                    options={accNetworkGroupOptions.map((option) => option.label)}
                                    value={networkGroup}
                                    onChange={(event, newValue) => handleAccNetworkGroupOptions(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label="Select an Network Group"
                                            variant="outlined"
                                            onChange={(event) => handleAccNetworkGroupOptions(event.target.value)}
                                            error={!!errors.networkGroup}
                                        />
                                    )}
                                />
                                <FormHelperText>{errors.networkGroup}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin="normal" error={!!errors.plan}>
                                <InputLabel>Plan</InputLabel>
                                <Autocomplete
                                    freeSolo
                                    options={options.map((option) => option.label)}
                                    value={plan}
                                    onChange={(event, newValue) => handlePlanChange(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label="Select an Plan"
                                            variant="outlined"
                                            onChange={(event) => handlePlanChange(event.target.value)}
                                            error={!!errors.plan}
                                        />
                                    )}
                                />
                                <FormHelperText>{errors.plan}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin="normal" error={!!errors.deviceList}>
                                <InputLabel>Device Prodct Code</InputLabel>
                                <Autocomplete
                                    required
                                    freeSolo
                                    options={options2.map((option) => option.label)}
                                    value={deviceList}
                                    onChange={(event, newValue) => handleDeviceListChange(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label="Device Prodct Code"
                                            variant="outlined"
                                            onChange={(event) => handleDeviceListChange(event.target.value)}
                                            error={!!errors.deviceList}
                                        />
                                    )}
                                />
                                <FormHelperText>{errors.deviceList}</FormHelperText>
                                <TextField
                                    required
                                    label="TAC code"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={tacCode}
                                    onChange={handleTacCodeChange}
                                    onBlur={handleTacCodeChange}
                                    error={!!errors.tacCode}
                                    helperText={errors.tacCode}
                                />
                                <a href="https://teamtelstra.sharepoint.com/:x:/r/teams/PCMT14-9/_layouts/15/Doc.aspx?sourcedoc=%7Be59f5237-0277-4f3c-8020-de53058fe8b9%7D&action=default&mobileredirect=true"
                                    target="_blank">Please Refer this Excel for TAC codes</a>
                            </FormControl>
                        </>
                    )}
                    {radioValue === 'Plan With Device' || radioValue === 'Plan Only' && (
                        <>
                            <FormControl fullWidth margin="normal" error={!!errors.plan}>
                                <InputLabel>CPO Product Code Options</InputLabel>
                                <Autocomplete
                                    freeSolo
                                    options={cpoProductCodeOptions.map((option) => option.label)}
                                    value={cpoProductCode}
                                    onChange={(event, newValue) => handlecpoProductCode(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label="Select an CPO Product Code"
                                            variant="outlined"
                                            onChange={(event) => handlecpoProductCode(event.target.value)}
                                            error={!!errors.cpoProductCode}
                                        />
                                    )}
                                />
                                <FormHelperText>{errors.cpoProductCode}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin="normal" error={!!errors.flexiPlanProductCode}>
                                <InputLabel>Flexi Plan Product Code</InputLabel>
                                <Autocomplete
                                    freeSolo
                                    options={flexiPlanProductCodeOptions.map((option) => option.label)}
                                    value={flexiPlanProductCode}
                                    onChange={(event, newValue) => handleFlexiPlanProductCodeOptions(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label="Select an Flexi Plan Product Code"
                                            variant="outlined"
                                            onChange={(event) => handleFlexiPlanProductCodeOptions(event.target.value)}
                                            error={!!errors.flexiPlanProductCode}
                                        />
                                    )}
                                />
                                <FormHelperText>{errors.flexiPlanProductCode}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin="normal" error={!!errors.networkGroup}>
                                <InputLabel>Network Group</InputLabel>
                                <Autocomplete
                                    freeSolo
                                    options={accNetworkGroupOptions.map((option) => option.label)}
                                    value={networkGroup}
                                    onChange={(event, newValue) => handleAccNetworkGroupOptions(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label="Select an Network Group"
                                            variant="outlined"
                                            onChange={(event) => handleAccNetworkGroupOptions(event.target.value)}
                                            error={!!errors.networkGroup}
                                        />
                                    )}
                                />
                                <FormHelperText>{errors.networkGroup}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin="normal" error={!!errors.plan}>
                                <InputLabel>Plan</InputLabel>
                                <Autocomplete
                                    freeSolo
                                    options={options.map((option) => option.label)}
                                    value={plan}
                                    onChange={(event, newValue) => handlePlanChange(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select an Plan"
                                            variant="outlined"
                                            onChange={(event) => handlePlanChange(event.target.value)}
                                            error={!!errors.plan}
                                        />
                                    )}
                                />
                                <FormHelperText>{errors.plan}</FormHelperText>
                            </FormControl>
                        </>)}
                    {radioValue === 'Plan with Device DUP' && (
                        <Alert variant="outlined" severity="info" style={{
                            "width": "300px",
                            "marginLeft": "200px"
                        }}>
                            Coming Soon
                        </Alert>
                    )}
                    <Box mt={2}>
                        <Button type="submit" variant="contained" color="primary" fullWidth disabled={trackArr.length > 0 || radioValue === "Plan with Device DUP"}>Submit</Button>
                    </Box>
                </form>
            </Paper> <br />
            {trackArr.length > 0 &&
                <Paper elevation={3} style={{ padding: '20px', maxWidth: '900px', margin: 'auto' }}>
                    <Box sx={{ width: '100%', padding: 2 }}>
                        <Stepper activeStep={activeStep}>
                            {trackArr.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        <Box sx={{ mt: 2, marginLeft: "115px" }}>
                            {loading && <CircularProgress style={{ marginLeft: "275px" }} />}
                            {!errorMsg && <>
                                <Typography variant="h6" style={{ marginLeft: "100px" }}>
                                    {trackArr[activeStep] === "Account Creation" && "Account Creation process is started..."}
                                    {trackArr[activeStep] === "Product Creation" && "Product Creation is started ..."}
                                    {trackArr[activeStep] === "Account Creation (Plan Only)" && "Product Creation is started without Plan..."}
                                    {trackArr[activeStep] === "Fetching TDM Data" && "Please wait Fetching MSISDN, IMSI, and SIM ..."}
                                </Typography> <br />
                            </>}
                            {errorMsg && <Typography color={"error"} variant="h6" >{errorMsg}</Typography>}
                            {micaCustomer && Object.keys(micaCustomer).length > 0 && <Grid container>
                                <Grid item md={7}>
                                    <div className='userData'>
                                        <h4><b>User Details</b></h4>
                                        <h5>First Name {micaCustomer.firstName}</h5> <br />
                                        <h5>Last Name : {micaCustomer.lastName}</h5> <br />
                                        <h5>Date of Birth : {moment(micaCustomer.dob, 'DDMMYYYY').format('DD-MM-YYYY')}</h5> <br />
                                        <h5>Account Number : {accountNum}</h5> <br />
                                        <h5>Customer Type : {customType}</h5> <br />
                                    </div>
                                </Grid>
                                <Grid item md={5}>
                                    <h4><b>Mobile Entity</b></h4>
                                    <h5>MSISDN : {msisdn}</h5> <br />
                                    <h5>IMSI : {imsi}</h5> <br />
                                    <h5>SIM : {sim}</h5> <br />
                                    <h5>Product ID : {productId}</h5> <br />
                                </Grid>
                            </Grid>}
                        </Box>
                        {(!errorMsg && !loading) && <><DownloadButton csvData={micaCustomer} csvData2={mobileEntity}
                            accountNum={accountNum} /> <br /></>}
                        <br />
                        {showResetBtn && <Button type="button" variant="contained" color="error" fullWidth onClick={resetStateValue}>Reset</Button>}
                    </Box>
                </Paper >}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        teamID: state.auth.team,
        dNumber: state.auth.userName,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        micaOrderCreate: (payload) => dispatch(micaOrderCreate(payload)),
        micaServiceCreate: (payload) => dispatch(micaServiceCreate(payload)),
        getSimData: (payload) => dispatch(getSimData(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MicaForm);

