import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    searchPending: false,
    searchSuccess: false,
    searchFailMsg: "",
    searchResultData: null,
    searchRequestPayload: {
        account: {
            filters: {}
        }
    },
    fetchLovData: {},
    nextRecordsUrls: null,
    salesforceCreation: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SALESFORCE_RESET:
            return {
                ...INITIAL_STATE
            }
        case types.SALESFORCE_SEARCH_PENDING:
            return {
                ...state,
                searchPending: true,
                searchSuccess: false,
                searchFailMsg: ""
            }
        case types.SALESFORCE_SEARCH_SUCCESS:
            return {
                ...state,
                searchPending: false,
                searchSuccess: true,
                searchFailMsg: "",
                searchResultData: {
                    ...state.searchResultData,
                    [action.payload.name]: action.payload.data
                }
            }
        case types.RESET_NEXT_RECORDS_URL:
            return {
                ...state,
                nextRecordsUrls: null
            }
        case types.UPDATE_NEXT_RECORDS_URL:
            return {
                ...state,
                nextRecordsUrls: {
                    ...state.nextRecordsUrls,
                    [action.payload.name]: action.payload.salesforceSearchUrl
                }
            }
        case types.SALESFORCE_SEARCH_FAILED:
            return {
                ...state,
                searchPending: false,
                searchSuccess: false,
                searchFailMsg: action.payload.msg,
                searchResultData: {
                    ...state.searchResultData,
                    [action.payload.name]: action.payload.clear ? [] : (state.searchResultData?.[action.payload.name] || [])
                }
            }
        case types.SALESFORCE_FETCH_LOV_PENDING:
            return {
                ...state,
                fetchLovData: {
                    ...state.fetchLovData,
                    [action.payload.entity]: {
                        ...state.fetchLovData[action.payload.entity],
                        [action.payload.field]: {
                            pending: true,
                            success: false,
                            failMsg: '',
                            list: []
                        }
                    }
                }
            }
        case types.SALESFORCE_FETCH_LOV_SUCCESS:
            return {
                ...state,
                fetchLovData: {
                    ...state.fetchLovData,
                    [action.payload.entity]: {
                        ...state.fetchLovData[action.payload.entity],
                        [action.payload.field]: {
                            pending: false,
                            success: true,
                            failMsg: '',
                            list: action.payload.response
                        }
                    }
                }
            }
        case types.SALESFORCE_LOV_RESET:
            return {
                ...state,
                fetchLovData: {
                    ...state.fetchLovData,
                    [action.payload.entity]: {
                        ...state.fetchLovData[action.payload.entity],
                        [action.payload.field]: {
                            ...state.fetchLovData[action.payload.field],
                            list: []
                        }
                    }
                }
            }
        case types.SALESFORCE_FETCH_LOV_FAILED:
            return {
                ...state,
                fetchLovData: {
                    ...state.fetchLovData,
                    [action.payload.entity]: {
                        ...state.fetchLovData[action.payload.entity],
                        [action.payload.field]: {
                            pending: false,
                            success: false,
                            failMsg: action.payload.msg,
                            list: []
                        }
                    }
                }
            }
        case types.SALESFORCE_CREATION:
            return {
                ...state,
                salesforceCreation: action.payload
            }
        default:
            return state;
    }
}