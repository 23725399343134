import React from 'react';
import { CssBaseline, Button, IconButton, Box } from '@mui/material';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
const IconRoundedButton = ({resetCurrentTab}) => {
  return (
    <>
      {/* CssBaseline ensures a consistent reset of styles across browsers */}
      <CssBaseline />
      <Box display="flex" justifyContent="center" alignItems="center" style={{marginLeft:"40px",marginRight:"20px"}}>
        <Button 
          sx={{
            width: 120,
            height: 36,
          }}
          variant="contained" 
          color="error"
          title='Reset'
          onClick={resetCurrentTab}
          endIcon={<RestartAltOutlinedIcon sx={{ fontSize: 30 }} />}
        >
           Reset
        </Button>
      </Box>
    </>
  );
};

export default IconRoundedButton;
