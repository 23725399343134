import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Swal from "sweetalert2";
import { displayLoading } from "../../common/alerts/alerts";
import { Button, Radio, RadioGroup, TextField, Select, MenuItem, FormControl, FormControlLabel, InputLabel, Dialog, DialogContentText, DialogTitle, DialogContent, DialogActions, dialogStatus, FormHelperText } from '@mui/material';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AlertDialogSlide({ accountNumber,setAccountNumber, open, handleClose, dynamicCustomer, setDynamicCustomer, dialogStatus, setDialogStatus, setPopupStatus, setBvtType, env, salesforceAccountNumber, salesforceCreation }) {
    const [accountNumberError, setAccountNumberError] = useState(null);

    useEffect(() => {
        if (accountNumber) {
            if (!accountNumber) {
                setAccountNumberError('Account Number is required');
            } else if (!/^[0-9]+$/.test(accountNumber)) {
                setAccountNumberError('Account Number must be a number');
            } else {
                setAccountNumberError('');
            }
        }
    }, [accountNumber]);

    const splitName = (fullName) => {
        const nameParts = fullName.trim().split(/\s+/); // Split by any whitespace
        const fname = nameParts[0] || '';
        const lname = nameParts[nameParts.length - 1] || '';
        const mname = nameParts.length > 2 ? nameParts.slice(1, nameParts.length - 1).join(' ') : '';
        return {
            fname,
            mname,
            lname
        };
    }


    const accountNumberSubmit = () => {
        handleClose();
        displayLoading({ title: "Submitting Account Number", bodyText: "Please standby fetching user data" });
        salesforceAccountNumber(env, accountNumber).then((result) => {
            if (result?.responseStatus === "Fail") {
                Swal.fire("Failed", result?.responseDescription, 'error');
            } else {
                let resData = result?.data;
                let AccountData;
                const { Account, Contact } = resData;
                if (resData?.Account) {
                    let nameList = splitName(Account?.Name);
                    AccountData = { ...Account, ...nameList };
                }
                Swal.close();
                setDynamicCustomer({ Contact, Account: AccountData });
                setPopupStatus(true);
                setBvtType("NON-BVT");

            }
        })
    }

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <center>
                    <DialogTitle sx={{ m: 0, p: 3, width: "600px" }} id="customized-dialog-title">
                        Do you have an Existing Account ?
                    </DialogTitle>
                </center>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <FormControl style={{ marginLeft: "215px" }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={dialogStatus}
                                onChange={(event) => {
                                    setDialogStatus(event.target.value);
                                }}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 32,
                                    },
                                }}
                            >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {dialogStatus === "Yes" && <div className="accountNumber" style={{ marginLeft: "120px" }}> <br />
                            <TextField
                                name="accountNumber"
                                value={accountNumber ? accountNumber : accountNumber}
                                onChange={(e) => { setAccountNumber(e.target.value) }}
                                required
                                error={accountNumberError}
                                helperText={accountNumberError}
                                id="outlined-basic" label="Account Number"
                                variant="outlined"
                                style={{ width: "85%", height: "40px", marginTop: "5px" }} /> <br /><br />
                            <Button variant="contained"
                                disabled={accountNumberError === "" ? false : true}
                                color="primary" onClick={accountNumberSubmit}
                                style={{ width: "215px", marginTop: "30px", marginLeft: "170px" }} >Submit</Button> <br />
                        </div>}
                    </Typography>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
}