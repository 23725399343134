import React from 'react';
import { Grid, Typography, Divider, Card, CardContent, Box, Button } from '@mui/material';

const AccountContactDetails = ({ data, randomData, setPersonalDataType }) => {
  const { Account, Contact } = data;

  return (
    <Box sx={{ padding: 4, backgroundColor: '#f7f7f7' }}>
      <Button
        style={{ marginLeft: "93%" }}
        variant="contained"
        color="primary"
        onClick={() => {
          setPersonalDataType(null);
          randomData();
        }}
      >
        Reset
      </Button> <br /><br />
      <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', color: '#333' }}>
        Account and User Details
      </Typography>

      {/* One card containing both Account and User Details */}
      <Card sx={{ borderRadius: 2, boxShadow: 3, backgroundColor: '#fff', padding: 2 }}>
        <CardContent>
          <Grid container spacing={4}>
            {/* Account Details Section on the Right */}
            <Grid item xs={12} sm={5}>
              <Box sx={{ paddingLeft: 3 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                  Account Information
                </Typography>
                <Typography sx={{ fontSize: '20px', color: '#666', marginTop: '15px' }}>
                  <strong>Name:</strong> {Account.Name}
                </Typography>
                <Typography sx={{ fontSize: '20px', color: '#666' }}>
                  <strong>Type:</strong> {Account.Type}
                </Typography>
                <Typography sx={{ fontSize: '20px', color: '#666' }}>
                  <strong>Customer ID:</strong> {Account.CustomerId__c}
                </Typography>
                <Typography sx={{ fontSize: '20px', color: '#666' }}>
                  <strong>Account ID:</strong> {Account.Id}
                </Typography>
              </Box>
            </Grid>
            {/* Divider */}
            <Grid item xs={1}>
              <Divider orientation="vertical" flexItem sx={{ height: '100%', width: '1px', backgroundColor: '#ccc' }} />
            </Grid>
            {/* User Details Section on the Left */}
            <Grid item xs={12} sm={6}>
              <Box sx={{ paddingRight: 3 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                  User Details
                </Typography>
                {Contact.length > 0 && Contact.map((contact, index) => (
                  <div key={index} style={{ marginTop: '15px' }}>
                    <Typography sx={{ fontSize: '20px', color: '#666' }}>
                      <strong>Role:</strong> {contact.role}
                    </Typography>
                    <Typography sx={{ fontSize: '20px', color: '#666' }}>
                      <strong>Name:</strong> {contact.FirstName} {contact.LastName}
                    </Typography>
                    <Typography sx={{ fontSize: '20px', color: '#666' }}>
                      <strong>Email:</strong> {contact.Email}
                    </Typography>
                    <Typography sx={{ fontSize: '20px', color: '#666' }}>
                      <strong>Mobile:</strong> {contact.MobilePhone}
                    </Typography>
                    <Typography sx={{ fontSize: '20px', color: '#666' }}>
                      <strong>Status:</strong> {contact.vlocity_cmt__Status__c}
                    </Typography>
                  </div>
                ))}
              </Box>
            </Grid>

          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default AccountContactDetails;
