const axios = require("axios");
import Swal from "sweetalert2";
let sqiBaseURL = 'https://svsqi-ptoms-b2ctop2o-b2c.sv.telstra-cave.com';
import { authHeaders } from "../utils/api";
import config from '../config';
const API_URL_GATEWAY = config.tdm_gateway_api.uri;

/* SQI Billing Action Methods start */

export const getBillingAccounts = (customerId) => async () => {
    return axios.get(`${sqiBaseURL}/billing/accounts/v2/billing-accounts/customer-account-code/${customerId}`, { headers: authHeaders() }).then((result) => {
        return result
    }).catch((error) => {
        return error.response.data;
    })
}

export const getBillingms = (customerId) => async () => {
    return axios.get(`${sqiBaseURL}/sqi/retrive/billingms/master-data/details?customerAccountCode=${customerId}`, { headers: authHeaders() }).then((result) => {
        return result
    }).catch((error) => {
        return error.response.data;
    })
}

export const addBill = (jsonData) => async () => {
    return axios.post(`${sqiBaseURL}/SSP-Billing/CAC/Add`, jsonData, { headers: authHeaders() }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}

export const deleteBill = (customerId) => async () => {
    return axios.delete(`${sqiBaseURL}/SSP-Billing/v1/CAC/${customerId}/Clear`, { headers: authHeaders() }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}

/* SQI Billing Action Methods End */

/* SQI Carbon Action Methods Start */

export const getSqiPayload = (payload) => async () => {
    return axios.post(`${API_URL_GATEWAY}/tdm/salesforce/order/stub/util`, payload, { headers: authHeaders() }).then((result) => {
        return result
    }).catch((error) => {
        return error.response.data;
    })
}

export const getCarbonEcom = (serviceId, accountUuid) => async () => {
    return axios.get(`${sqiBaseURL}/v1/customer-products/my/products?serviceId=${serviceId}&accountUuid=${accountUuid}`, { headers: authHeaders() }).then((result) => {
        return result
    }).catch((error) => {
        return error.response.data;
    })
}

export const getCarbonAgent = (serviceId, accountUuid, customerId) => async () => {
    return axios.get(`${sqiBaseURL}/v1/customer-products/products?serviceId=${serviceId}&accountUuid=${accountUuid}&customerId=${customerId}`, { headers: authHeaders() }).then((result) => {
        return result
    }).catch((error) => {
        return error.response.data;
    })
}

export const getCarbonMocResponse = (id) => async () => {
    return axios.get(`${sqiBaseURL}/api/utility/carbon/viewData?id=${id}`, { headers: authHeaders() }).then((result) => {
        return result
    }).catch((error) => {
        return error.response.data;
    })
}

export const carbonAddBill = (jsonData) => async () => {
    return axios.post(`${sqiBaseURL}/api/utility/carbon/addData`, jsonData, { headers: authHeaders() }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}

export const carbonDeleteBill = (id) => async () => {
    return axios.delete(`${sqiBaseURL}/api/utility/carbon/deleteData?id=${id}`, { headers: authHeaders() }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}
/* SQI Carbon Action Methods End */

/* SQI Balance Action Methods Start */

export const getAccountID = (payload) => async () => {
    return axios.post(`${API_URL_GATEWAY}/tdm/salesforce/search/account/customerId`, payload, { headers: authHeaders() }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}

export const getPaymentReference = (id, memberId) => async () => {
    let headers = authHeaders();
    headers['ds-correlation-id'] = memberId;
    headers['application_label'] = 'svc-bm2-payment-reference';
    return axios.get(`${sqiBaseURL}/v1/balances/customer-accounts/${id}/payment-references?product=TelcoBill&brand=Telstra`, { headers }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}

export const getBalance = (id, memberId) => async () => {
    let headers = authHeaders();
    headers['ds-correlation-id'] = memberId;
    headers['application_label'] = 'svc-bm2-payment-reference';
    return axios.get(`${sqiBaseURL}/v1/balances/customer-accounts/${id}/balances`, { headers }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}

export const getBalanceMocRes = (id) => async () => {
    return axios.get(`${sqiBaseURL}/api/utility/prn/viewData?accountUuid=${id}`, { headers: authHeaders() }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}

export const balancePost = (id, payload) => async () => {
    return axios.post(`${sqiBaseURL}/v1/balances/customer-accounts/${id}/payment-references`, payload, { headers: authHeaders() }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}

export const generatePrn = (payload) => async () => {
    return axios.post(`${sqiBaseURL}/api/utility/prn/addData`, payload, { headers: authHeaders() }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}

export const postBalanceMoc = (payload, prn, id) => async () => {
    let headers ={};
    headers['interface'] = "balancePRNP2OD";
    headers['http_code'] = 200;
    headers['prn'] = prn;
    headers['id'] = id;
    return axios.post(`${sqiBaseURL}/api/utility/prn/customresponse`, payload, { headers }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}

export const updateBalanceMoc = (prn, payload) => async () => {
    return axios.put(`${sqiBaseURL}/api/utility/prn/updateData?prn=${prn}`, payload, { headers: authHeaders() }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}

export const deleteBalanceMoc = (id) => async () => {
    return axios.delete(`${sqiBaseURL}/api/utility/prn/deleteData?accountUuid=${id}`, { headers: authHeaders() }).then((result) => {
        return result;
    }).catch((error) => {
        if (error?.responseStatus === "Fail") {
            return Swal.fire("Failed", error?.responseDescription, 'error');
        } return error;
    })
}

/* SQI Balance Action Methods End */
