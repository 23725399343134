import React, { useEffect, useState } from 'react';
import {
    Grid,
} from "@material-ui/core";
import Swal from "sweetalert2";
import { displayLoading } from "../../common/alerts/alerts";
import { Button, TextField, Select, InputLabel, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/lab';
import moment from "moment";
import AccountContactDetails from "./AccountContactDetails";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const ResidentialForm = ({ onChange, handleDateChange, formValues, formErrors, bvtType, validateField, randomData, salutationList, authorityList, customerCreationAction, env, setPersonalDataType }) => {
    const { firstName, middleName, lastName, birthdate, email, phoneNo, salutation, authority } = formValues;
    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 19, today.getMonth(), today.getDate());
    const getValue = Object.values(formErrors);
    const [residentialResult, setResidentialResult] = useState(null);

    const handleSubmit = () => {
        displayLoading({ title: "Customer Creation", bodyText: "Please standby while we Submit Customer Creation Request" });
        let obj;
        if (env === "SQNI") {
            obj = {
                "PhysicalAddress__c": "a2r9h000001EeTF",
                "PostalAddress__c": "a2r9h000001EeTF"
            }
        } else {
            obj = {
                "PhysicalAddress__c": "a2r2N000003gwO2QAI",
                "PostalAddress__c": "a2r2N000003gwO2QAI",
            }
        }
        let payload = {
            "customer": {
                "Name": `${firstName} ${middleName} ${lastName}`,
                "Type": "Residential",
                "BusinessUnitCode__c": "C",
                "attributes": {
                    "type": "Account"
                }
            },
            "contact": {
                "Salutation": salutation,
                "LastName": lastName,
                "MiddleName": middleName,
                "FirstName": firstName,
                "Birthdate": moment(birthdate).format("YYYY-MM-DD"),
                "Email": email,
                "MobilePhone": phoneNo,
                "UIAC_RoleUponCreation__c": authority,
                "LanguagePreference__c": "English",
                "PreferredCommunicationMethod__c": "None",
                "vlocity_cmt__Status__c": "Active",
                "PhysicalAddress__c":obj.PhysicalAddress__c,
                "PostalAddress__c":obj.PostalAddress__c
            }
        }
        let getResponse = customerCreationAction(env, payload);
        getResponse.then((result) => {
            if (result?.responseStatus === "Fail") {
                return Swal.fire("Failed", result?.responseDescription, 'error');
            }
            if (result?.status === 200) {
                Swal.fire({
                    type: 'success',
                    title: 'Successfully customer creation is created',
                }).then(() => {
                    setResidentialResult(result?.data)
                })
            }
        }).catch((error) => {
            if (error?.responseStatus === "Fail") {
                Swal.fire("Failed", error?.responseDescription, 'error');
            }
        })
    }

    if (residentialResult) {
        return <AccountContactDetails data={residentialResult} setPersonalDataType={setPersonalDataType} randomData={randomData} />;
    } return <div style={{ width: "700px", marginLeft: "200px" }}>
        <Button
            style={{ marginLeft: "87%" }}
            variant="contained"
            color="primary"
            onClick={randomData}
        >
            Random
        </Button> <br /><br />
        <Grid item xs={12}>
            <InputLabel id="demo-simple-select-label">Salutation</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={salutation}
                name="salutation"
                label="Salutation"
                onChange={onChange}
                fullWidth
            >
                {salutationList.map((_item) => {
                    return <MenuItem key={_item} value={_item}>{_item}</MenuItem>
                })}
            </Select>
        </Grid>
        <br />
        <Grid item xs={12}>
            <InputLabel id="demo-simple-select-label">Authority Type</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={authority}
                name="authority"
                label="authority"
                onChange={onChange}
                fullWidth
            >
                {authorityList.map((_item) => {
                    return <MenuItem key={_item} value={_item}>{_item}</MenuItem>
                })}
            </Select>
        </Grid>
        <br />
        <Grid item xs={12}>
            <TextField
                label="First Name"
                name="firstName"
                value={firstName}
                required
                fullWidth
                onChange={onChange}
                error={!!formErrors.firstName}
                helperText={formErrors.firstName}
            />
        </Grid>
        <br />
        <><TextField
            label="Middle Name"
            name="middleName"
            value={middleName || ""}
            fullWidth
            onChange={onChange}
            error={!!formErrors.middleName}
            helperText={formErrors.middleName}
        /><br /><br /> </>

        <TextField
            label="Last Name"
            name="lastName"
            value={bvtType === "BVT" ? "TEST" : lastName}
            required
            fullWidth
            onChange={(e) => {
                if (bvtType === "NON-BVT") {
                    onChange(e);
                }
            }}
            error={!!formErrors.lastName}
            helperText={formErrors.lastName}
        />
        <br /><br />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label="Birthdate"
                value={birthdate}
                maxDate={eighteenYearsAgo}
                defaultCalendarMonth={eighteenYearsAgo}
                onChange={(date) => handleDateChange('birthdate', date)}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} required fullWidth error={!!formErrors.birthdate} helperText={formErrors.birthdate} />}
            />
        </LocalizationProvider>
        <br /><br />
        <TextField
            label="Email"
            name="email"
            value={email}
            required
            fullWidth
            onChange={onChange}
            error={!!formErrors.email}
            helperText={formErrors.email}
        />
        <br /><br />

        <TextField
            label="Phone No"
            name="phoneNo"
            value={phoneNo}
            required
            fullWidth
            onChange={onChange}
            error={!!formErrors.phoneNo}
            helperText={formErrors.phoneNo}
        />
        <br /><br />
        <Button
            style={{ marginLeft: "87%" }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={getValue.length === 0 ? false : !getValue.every(item => item === "")}
        >
            Submit
        </Button>
    </div>
}

export default ResidentialForm;