import HeaderContent from '../../common/Header/HeaderContent';
import DataTilesFrame from "../../Data/accordion-frames/DataTilesFrame";
import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Typography, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormHelperText } from '@mui/material';
import ReactJson from 'react-json-view';
import ArrowBackIcon from '@mui/icons-material/Refresh';
import { Alert } from '@mui/material';
import { connect } from "react-redux";
import { getBillingms, getBillingAccounts, addBill, deleteBill } from "../../../actions/sqiActions"
import SqiCarbon from './sqiCarbon';
import SpiBalance from './sqiBalance';

const Sqibilling = ({ history, getBillingAccounts, getBillingms, addBill, deleteBill }) => {
    const [loading, setLoading] = useState(false);
    const [sqiUtility, setSqiUtility] = useState(null);
    const [SFResponse, setSFJsonResponse] = useState(null);
    const [mocResponse, setMocResponse] = useState(null);
    const [jsonResponse, setJsonResponse] = useState(null);
    const [customerId, setCustomerId] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [customerIdError, setCustomerIdError] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // For delete confirmation dialog
    const [actionMessage, setActionMessage] = useState({});
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false); // For showing success message popup
    const [openInputJson, setOpenInputJson] = useState(false); // For showing success message popup

    const [jsonData, setJsonData] = useState('');
    const [error, setError] = useState('');
    const [validJson, setValidJson] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setJsonData(value);
        validateJson(value);
    };

    useEffect(() => {
        if (openInputJson) {
            setJsonData(JSON.stringify({
                "customerAccountCode": customerId,
                "customerStatus": "established",
                "billingNumber": customerId,
                "billingStatus": "ACQUIRED",
                "collectionsIndicator": "true",
                "frequency": "QUARTERLY",
                "cycleDay": "05"
            }, null, 2))
        }
    }, [openInputJson])

    const validateJson = (value) => {
        try {
            // Try to parse the input as JSON
            const parsedData = JSON.parse(value);

            // Optionally, validate the structure of the parsed JSON
            // Example: check if the JSON has a specific field (e.g., 'name')
            if (parsedData) {
                setValidJson(true);
                setError('');
            } else {
                setValidJson(false);
                setError('Invalid JSON structure');
            }
        } catch (e) {
            setValidJson(false);
            setError('Invalid JSON format.');
        }
    };


    // Validate while typing (only numbers allowed)
    const handleCustomerIdChange = (e) => {
        const value = e.target.value;
        setCustomerId(value);
        if (isNaN(value) || value === '') {
            setCustomerIdError(true);
        } else {
            setCustomerIdError(false);
        }
    };

    // Simulate fetching data after submission
    useEffect(() => {
        if (SFResponse && mocResponse) {
            setLoading(false);
        }
    }, [SFResponse, mocResponse]);

    const handleSubmit = () => {
        setLoading(true);
        let errorMsg = { Info: `No records found for the given customerAccountCode:${customerId}`, Error: "404" }

        let res1 = getBillingAccounts(customerId);
        res1.then((result) => {
            if (result?.data) {
                setSFJsonResponse(result.data);
            } else {
                setSFJsonResponse({ Info: result, Error: "500" });
            }
        }).catch((error) => {
            setSFJsonResponse(errorMsg);
        });

        let res2 = getBillingms(customerId);

        res2.then((result) => {
            if (result?.data) {
                setMocResponse(result?.data)
            } else {
                setMocResponse({ Info: result })
            }
        }).catch((error) => {
            setMocResponse(errorMsg)
        })
    };

    const handlePostAction = () => {
        setLoading(true);
        setOpenInputJson(false);
        setJsonData('');
        let result = addBill(jsonData);
        result.then((res) => {
            handleSubmit();
        })
    };

    const handleDeleteAction = () => {
        setOpenDeleteDialog(true);
    };

    const handleDeleteConfirm = () => {
        setSFJsonResponse(null);
        setMocResponse(null);
        let result = deleteBill(customerId);
        result.then((res) => {
            handleSubmit();
            setOpenDeleteDialog(false)
        });
    };

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    };

    const handleCloseSuccessDialog = () => {
        setOpenSuccessDialog(false); // Close the success dialog
    };

    const handleReset = () => {
        setLoading(false);
        setJsonResponse(null);
        setCustomerId('');
        setIsSubmitted(false);
        setCustomerIdError(false);
        setOpenDeleteDialog(false);
        setActionMessage('');
        setOpenSuccessDialog(false);
        setSFJsonResponse(null);
        setMocResponse(null)
    };

    return (
        <div className="white" >
            <HeaderContent
                title={`SQI STUB Utilities`}
                icon="cogs ops-icon"
                empty={true}
                redirectUtility={true}
                push={history?.push}></HeaderContent>
            <DataTilesFrame
                color="turquoise"
                data={[
                    {
                        label: 'SQI Billing MS',
                        icon: "icon-sim-card"
                    },
                    {
                        label: 'SQI Carbon',
                        icon: "icon-search-find"
                    },
                    {
                        label: 'SQI Balance API',
                        icon: "icon-bill-paper"
                    },
                    {
                        label: 'SQI Payment API',
                        icon: "icon-shop-online"
                    }
                ]}
                select={(data) => setSqiUtility(data.label)}
                selected={sqiUtility}
            />
            {sqiUtility && sqiUtility === "SQI Billing MS" ? <Box sx={{ padding: 3 }}>
                {!loading && (!mocResponse && !SFResponse) &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>

                            {/* TextField and Submit Button */}
                            <Box sx={{ marginBottom: 3, width: "500px", marginLeft: "30px" }}>
                                <TextField
                                    label="Enter the Customer ID"
                                    variant="outlined"
                                    fullWidth
                                    value={customerId}
                                    onChange={handleCustomerIdChange}
                                    sx={{ marginBottom: 2 }}
                                    error={customerIdError}
                                    disabled={SFResponse && mocResponse}
                                />
                                {customerIdError && (
                                    <FormHelperText error>Customer ID must be a valid number.</FormHelperText>
                                )}
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    sx={{
                                        width: '300px',
                                        padding: '10px',
                                        backgroundColor: '#1976d2',
                                        '&:hover': { backgroundColor: '#1565c0' },
                                        marginTop: 2,
                                        marginLeft: "196px",
                                    }}
                                    disabled={(customerIdError || !customerId) || (SFResponse && mocResponse)} // Disable button if there's an error
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>}
                {mocResponse && SFResponse && !loading && <Grid item xs={12} md={6}>
                    <Box sx={{ marginBottom: 3, width: "500px", marginLeft: "1125px" }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<ArrowBackIcon />}
                            onClick={handleReset}
                            sx={{}}
                        >
                            Back
                        </Button>
                    </Box>
                </Grid>}

                {/* Show loading state or results */}
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <CircularProgress />
                    </Box>
                ) : (mocResponse && SFResponse && !loading && (
                    <Grid container spacing={3}>
                        {/* First Column */}
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    padding: 2,
                                    backgroundColor: 'rgb(228 226 226)',
                                    borderRadius: 1,
                                    minHeight: '200px',
                                }}
                            >
                                {/* Heading for the first column */}
                                <center>
                                    <Typography variant="h5" sx={{ marginBottom: 2, fontSize: '1.8rem' }}>
                                        SQI - SF Response
                                    </Typography>
                                </center>
                                <br />

                                {/* Render JSON data with 'tube' theme */}
                                <ReactJson src={SFResponse} theme="tube" style={{ overflow: "scroll", height: "400px", background: "#272822" }} />
                            </Box>
                        </Grid>

                        {/* Second Column */}
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    padding: 2,
                                    backgroundColor: 'rgb(228 226 226)',
                                    borderRadius: 1,
                                    minHeight: '200px',
                                }}
                            >
                                {/* Heading for the second column */}
                                <center>
                                    <Typography variant="h5" sx={{ marginBottom: 2, fontSize: '1.8rem' }}>
                                        SQI - DB MOC Response
                                    </Typography>
                                </center>
                                <br />

                                {/* Render JSON data with 'colors' theme */}
                                <ReactJson src={mocResponse} theme="colors" style={{ overflow: "scroll", height: "400px", background: "#272822" }} />

                                {/* Buttons */}
                                <Box sx={{ marginTop: 2, display: 'flex', gap: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            ':hover': { backgroundColor: '#1976d2' },
                                            padding: '10px 20px',
                                        }}
                                        // onClick={handlePostAction}
                                        onClick={() => setOpenInputJson(!openInputJson)}
                                    >
                                        POST
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            ':hover': { backgroundColor: '#d32f2f' },
                                            padding: '10px 20px',
                                        }}
                                        onClick={handleDeleteAction}
                                    >
                                        DELETE
                                    </Button>
                                </Box><br /><br />
                                {openInputJson && <>
                                    <TextField
                                        label="Enter JSON"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={9}
                                        value={jsonData}
                                        onChange={handleInputChange}
                                        error={!validJson}
                                        helperText={error}
                                        sx={{
                                            width: "550px",
                                        }}
                                    /> <br />
                                    <Button
                                        variant="contained"
                                        onClick={handlePostAction}
                                        sx={{
                                            width: '200px',
                                            padding: '10px',
                                            backgroundColor: '#1976d2',
                                            '&:hover': { backgroundColor: '#1565c0' },
                                            marginTop: 2,
                                            marginLeft: "345px",
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </>}
                            </Box>
                        </Grid>
                    </Grid>
                )
                )}
                {/* Delete Confirmation Dialog */}
                <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
                    <DialogTitle>Delete Confirmation</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteCancel} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteConfirm} color="error">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Success Message Dialog */}
                <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
                    <DialogTitle>Success</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" color="green">
                            {actionMessage}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSuccessDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box> : sqiUtility === "SQI Carbon" ? <SqiCarbon /> : null}
            {sqiUtility === "SQI Balance API" && <SpiBalance />}
            {sqiUtility === "SQI Payment API" && <Alert variant="outlined" severity="info" style={{
                "width": "350px",
                "marginLeft": "200px",
                "paddingLeft": "100px"
            }}>
                Coming Soon
            </Alert>}
        </div >
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBillingAccounts: (payload) => dispatch(getBillingAccounts(payload)),
        getBillingms: (payload) => dispatch(getBillingms(payload)),
        addBill: (payload) => dispatch(addBill(payload)),
        deleteBill: (payload) => dispatch(deleteBill(payload)),
    };
};

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sqibilling);