import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, CircularProgress, MenuItem, Select, FormControl, InputLabel, Button, FormHelperText, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactJson from 'react-json-view';
import { connect } from "react-redux";
import { getAccountID, getPaymentReference, getBalance, getBalanceMocRes, deleteBalanceMoc, generatePrn, postBalanceMoc, balancePost, updateBalanceMoc } from "../../../actions/sqiActions"
import { displayLoading, displayErrorNew, displaySuccess } from "../../common/alerts/alerts";
import swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import PaymentModal from './PaymentModal';

const SpiBalance = (props) => {
    const { getAccountID, getPaymentReference, getBalance, getBalanceMocRes, deleteBalanceMoc, generatePrn, postBalanceMoc, balancePost, updateBalanceMoc } = props;
    const [loading, setLoading] = useState(false);
    const [showJson, setshowJson] = useState(false);
    const [openInputJson, setOpenInputJson] = useState(false); // For showing success message popup
    const [leftopenInputJson, setLeftOpenInputJson] = useState(false); // For showing success message popup
    const [jsonData, setJsonData] = useState('');
    const [leftJsonData, setLeftJsonData] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // For delete confirmation dialog
    const [paymentReference, setCarbonEcomRes] = useState(null);
    const [balanceRes, setBalanceRes] = useState(null);
    const [BalanceMocResult, setBalanceMocResult] = useState(null);
    const [accountUuid, setAccountUuid] = useState(null);
    const [memberId, setMemberId] = useState(uuidv4());
    const [openModal, setOpenModal] = useState(false);
    const [typeOfAction, setTypeOfAction] = useState('');

    const handleOpen = (type) => {
        setTypeOfAction(type)
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const [formData, setFormData] = useState({
        customerId: ''
    });
    const [errors, setErrors] = useState({
        customerId: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Validation logic
        if (name === 'orderId') {
            if (!/^[A-Za-z]\d{14}$/.test(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    orderId: 'Order ID must start with a letter followed by 15 digits.',
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    orderId: '',
                }));
            }
        }

        if (name === 'serviceId') {
            if (!/^\d{11}$/.test(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    serviceId: 'Service ID must be exactly 11 digits.',
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    serviceId: '',
                }));
            }
        }

        if (name === 'template') {
            if (value === '') {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    template: 'Template is required.',
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    template: '',
                }));
            }
        }
    };

    function isPlainObject(value) {
        return Object.prototype.toString.call(value) === '[object Object]';
    }

    useEffect(() => {
        if (isPlainObject(jsonData)) {
            if (openInputJson) {
                setJsonData(JSON.stringify(jsonData, null, 2))
            }
        }
    }, [openInputJson]);

    useEffect(() => {
        if (isPlainObject(leftJsonData)) {
            if (leftopenInputJson) {
                setLeftJsonData(JSON.stringify(leftJsonData, null, 2))
            }
        }
    }, [leftopenInputJson])

    useEffect(() => {
        if (paymentReference && balanceRes && BalanceMocResult) {
            setLoading(false);
            setshowJson(true);
        }
    }, [paymentReference, balanceRes, BalanceMocResult]);

    const handleSubmit = () => {
        const { customerId } = formData;
        // Check if there are any errors before submitting the form
        const isFormValid = Object.values(errors).every((error) => error === '') &&
            Object.values(formData).every((field) => field !== '');
        if (isFormValid) {
            displayLoading({
                title: "Triggering TDM Service",
                bodyText: "Please standby Fetching Account UUID",
            });
            let payload = {
                customerId,
                "environment": "SQNI"
            }
            let errorMsg = { Info: `No records found `, Error: "404" }

            getAccountID(payload).then((response) => {
                swal.close();
                if (response) {
                    if (response.status === 200) {
                        let jsonDataRes = response?.data;
                        const { customer } = jsonDataRes;
                        if (customer.length === 0) {
                            displayErrorNew({
                                title: "Invalid customer ID",
                                subTitle: "Customer is Not present in Salesforce",
                            })
                        } else {
                            let obj = {
                                "accountuuid": customer[0]?.account.accountUuid,
                                "status": "Active",
                                "product": "TelcoBill",
                                "chargeModel": "AUTOPAY",
                                "cycleDay": "8",
                                "cycleFrequency": "MONTHLY",
                                "brand": "Telstra",
                                "nextAnniversaryDate": "15-06-2024",
                                "members": [
                                    {
                                        "id": memberId,
                                        "status": "Active"
                                    }
                                ]
                            }
                            setJsonData(obj);
                            setLeftJsonData({ "product": "TelcoBill", "chargeModel": "AUTOPAY", "member": { "id": memberId } })
                            setLoading(true);
                            setAccountUuid(customer[0]?.account.accountUuid);
                            let res1 = getPaymentReference(customer[0]?.account.accountUuid, memberId);
                            let res2 = getBalance(customer[0]?.account.accountUuid, memberId);
                            let res3 = getBalanceMocRes(customer[0]?.account.accountUuid);
                            res1.then((result) => {
                                if (result?.data) {
                                    setCarbonEcomRes(result?.data);
                                } else {
                                    setCarbonEcomRes({ Info: result, Error: "500" });
                                }
                            }).catch((error) => {
                                setCarbonEcomRes(errorMsg);
                            });
                            res2.then((result) => {
                                if (result?.data) {
                                    setBalanceRes(result.data);
                                } else {
                                    setBalanceRes({ Info: result, Error: "500" });
                                }
                            }).catch((error) => {
                                setBalanceRes(errorMsg);
                            });
                            res3.then((result) => {
                                if (result?.data) {
                                    setBalanceMocResult(result.data)
                                } else {
                                    setBalanceMocResult({ Info: result, Error: "500" });
                                }
                            }).catch((error) => {
                                setBalanceMocResult(errorMsg);
                            });
                        }
                    } else {
                        displayErrorNew({
                            title: response?.responseStatus,
                            subTitle: response?.status,
                            errorMsg: response?.error
                        })
                    }
                }
            });

        } else {
            let error = {
                orderId: formData.orderId ? "" : "Order Id is required",
                serviceId: formData.serviceId ? "" : "Service Id is required",
                template: "Template is required",
            }
            setErrors(error);
        }
    };

    const handleDeleteAction = (type) => {
        setTypeOfAction(type)
        setOpenDeleteDialog(true);
    };

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteConfirm = () => {
        setLoading(true);
        setOpenInputJson(false);
        let result = deleteBalanceMoc(accountUuid);
        result.then((res) => {
            setJsonData('');
            setLeftJsonData('');
            handleSubmit();
            setLoading(false);
            setOpenDeleteDialog(false)
        });
    };

    const handleReset = () => {
        setshowJson(false);
        setFormData({
            customerId: ''
        })
        setErrors({
            customerId: ''
        })
        setTypeOfAction('')
        setOpenModal(false)
        setAccountUuid(null)
        setBalanceMocResult(null)
        setBalanceRes(null)
        setCarbonEcomRes(null)
        setOpenDeleteDialog(false)
        setLeftJsonData('')
        setJsonData('')
        setLeftOpenInputJson(false)
        setOpenInputJson(false)
        setLoading(false)
    }

    const handlePostAction = () => {
        setLoading(true);
        setOpenInputJson(false);
        // setJsonData('');
        let parseJson = JSON.parse(jsonData);
        let result = generatePrn(parseJson);
        displayLoading({
            title: "Triggering PRN service",
            bodyText: "Please standby Fetching PRN value",
        });
        result.then((res) => {
            swal.close();
            let getPrn = res?.data?.prn;
            let result2 = postBalanceMoc(parseJson, getPrn);
            result2.then((res2) => {
                if (res2?.data?.status === "error") {
                    displayErrorNew({
                        title: res2?.data?.status,
                        subTitle: res2?.data?.reason,
                        errorMsg: res2?.data?.description
                    }).then(() => {
                        setJsonData('');
                        setLeftJsonData('');
                        handleSubmit();
                        setLoading(false);
                    })
                } else {
                    displaySuccess({
                        title: res2?.data?.status,
                        subTitle: res2?.data?.reason,
                        errorMsg: res2?.data?.description
                    }).then(() => {
                        setJsonData('');
                        setLeftJsonData('');
                        handleSubmit();
                        setLoading(false);
                    })
                }
            })
        });
    };

    const handleLeftPostAction = () => {
        setLoading(true);
        setOpenInputJson(false);
        setLeftOpenInputJson(false);
        // setJsonData('');
        let result = balancePost(accountUuid, leftJsonData);
        result.then((res) => {
            if (res?.data?.status === "error") {
                displayErrorNew({
                    title: res?.data?.status,
                    subTitle: res?.data?.reason,
                    errorMsg: res?.data?.description
                }).then(() => {
                    setJsonData('');
                    setLeftJsonData('');
                    handleSubmit();
                    setLoading(false);
                })
            } else {
                displaySuccess({
                    title: "Success",
                    subTitle: res?.data?.reason,
                    errorMsg: res?.data?.description
                }).then(() => {
                    setJsonData('');
                    setLeftJsonData('');
                    handleSubmit();
                    setLoading(false);
                })
            }
        });
    };

    const handleGeneratePRN = () => {
        displayLoading({
            title: "Triggering Generate PRN service",
            bodyText: "Please standby generating PRN value",
        });
        let parseJson;
        if (typeof jsonData === "string") {
            parseJson = JSON.parse(jsonData);
        } else {
            parseJson = jsonData;
        }
        //let parseJson = JSON.parse(jsonData);
        let result = generatePrn(parseJson);
        result.then((res) => {
            swal.close();
            displaySuccess({
                title: "Success",
                text: res.data?.info,
            }).then(() => {
                setJsonData('');
                setLeftJsonData('');
                handleSubmit();
                setLoading(false);
            })
        });
    }

    return (
        <>
            {!loading && !showJson && <form noValidate style={{ width: "500px", marginLeft: "135px" }} disabled={showJson}>
                <TextField
                    label="customerId"
                    name="customerId"
                    value={formData.customerId}
                    onChange={handleChange}
                    fullWidth
                    required
                    error={!!errors.customerId}
                    helperText={errors.customerId}
                />
                <br /> <br />
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={Object.values(errors).some((error) => error !== '')} style={{ marginLeft: "410px" }}>
                    Submit
                </Button>
            </form>}
            {!loading && showJson && <Box sx={{ marginBottom: 3, width: "500px", marginTop: "-90px" }}>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<ArrowBackIcon />}
                    onClick={handleReset}
                    sx={{ "marginTop": "95px", "marginLeft": "1160px" }}
                >
                    Back
                </Button>
            </Box>}
            {loading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress />
            </Box>}
            {!loading && showJson &&
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                padding: 2,
                                backgroundColor: 'rgb(228 226 226)',
                                borderRadius: 1,
                                minHeight: '200px',
                            }}
                        >
                            {/* Heading for the first column */}
                            <center>
                                <Typography variant="h5" sx={{ marginBottom: 2, fontSize: '1.8rem' }}>
                                    SQI - Payment Reference
                                </Typography>
                            </center>
                            <br />

                            {/* Render JSON data with 'tube' theme */}
                            <ReactJson src={paymentReference} theme="ashes" style={{ overflow: "scroll", height: "275px" }} />
                            {/* Heading for the first column */}
                            <br />
                            <center>
                                <Typography variant="h5" sx={{ marginBottom: 2, fontSize: '1.8rem' }}>
                                    SQI - Balance
                                </Typography>
                            </center>
                            <Box
                                sx={{
                                    padding: 2,
                                    backgroundColor: 'rgb(228 226 226)',
                                    borderRadius: 1,
                                    minHeight: '200px',
                                }}
                            >
                                {/* Render JSON data with 'tube' theme */}
                                <ReactJson src={balanceRes} theme="ashes" style={{ overflow: "scroll", height: "275px" }} />
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    ':hover': { backgroundColor: '#1976d2' },
                                    padding: '10px 20px',
                                    marginLeft: "500px"
                                }}
                                onClick={() => setLeftOpenInputJson(!leftopenInputJson)}
                            >
                                POST
                            </Button> <br /><br />
                            {leftopenInputJson && <>
                                <TextField
                                    label="Enter JSON"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={9}
                                    value={leftJsonData}
                                    // onChange={handleInputChange}
                                    // error={!validJson}
                                    // helperText={error}
                                    sx={{
                                        width: "550px",
                                    }}
                                /> <br />
                                <Button
                                    variant="contained"
                                    onClick={handleLeftPostAction}
                                    sx={{
                                        width: '200px',
                                        padding: '10px',
                                        backgroundColor: '#1976d2',
                                        '&:hover': { backgroundColor: '#1565c0' },
                                        marginTop: 2,
                                        marginLeft: "345px",
                                    }}
                                >
                                    Submit
                                </Button>
                            </>}
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                padding: 2,
                                backgroundColor: 'rgb(228 226 226)',
                                borderRadius: 1,
                                minHeight: '200px',
                            }}
                        >
                            {/* Heading for the second column */}
                            <center>
                                <Typography variant="h5" sx={{ marginBottom: 2, fontSize: '1.8rem' }}>
                                    SQI - DB MOC Response
                                </Typography>
                            </center>
                            <br />

                            {/* Render JSON data with 'colors' theme */}
                            <ReactJson src={BalanceMocResult} theme="ashes" style={{ overflow: "scroll", height: "275px" }} />
                            <br />
                            {/* Buttons */}
                            <Box sx={{ marginTop: 2, display: 'flex', gap: 2 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        ':hover': { backgroundColor: '#1976d2' },
                                        padding: '10px 20px',
                                    }}
                                    onClick={handleGeneratePRN}
                                >
                                    Generate PRN
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        ':hover': { backgroundColor: '#1976d2' },
                                        padding: '10px 20px',
                                    }}
                                    // onClick={handlePostAction}
                                    // onClick={() => setOpenInputJson(!openInputJson)}
                                    onClick={() => handleOpen("post")}
                                >
                                    POST
                                </Button>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    sx={{
                                        ':hover': { backgroundColor: '#1976d2' },
                                        padding: '10px 20px',
                                    }}
                                    // onClick={handlePostAction}
                                    onClick={() => handleOpen("update")}
                                >
                                    Update
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    sx={{
                                        ':hover': { backgroundColor: '#d32f2f' },
                                        padding: '10px 20px',
                                    }}
                                    onClick={() => handleDeleteAction("delete")}
                                >
                                    DELETE
                                </Button>
                            </Box><br /><br />
                            {openInputJson && <>
                                <TextField
                                    label="Enter JSON"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={9}
                                    value={jsonData}
                                    // onChange={handleInputChange}
                                    // error={!validJson}
                                    // helperText={error}
                                    sx={{
                                        width: "550px",
                                    }}
                                /> <br />
                                <Button
                                    variant="contained"
                                    onClick={handlePostAction}
                                    sx={{
                                        width: '200px',
                                        padding: '10px',
                                        backgroundColor: '#1976d2',
                                        '&:hover': { backgroundColor: '#1565c0' },
                                        marginTop: 2,
                                        marginLeft: "345px",
                                    }}
                                >
                                    Submit
                                </Button>
                            </>}
                        </Box>
                    </Grid>

                </Grid>
            }
            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="error">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            {BalanceMocResult && <PaymentModal
                open={openModal}
                handleClose={handleClose}
                BalanceMocResult={BalanceMocResult}
                updateBalanceMoc={updateBalanceMoc}
                postBalanceMoc={postBalanceMoc}
                typeOfAction={typeOfAction}
                accountuuid={accountUuid}
            />}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAccountID: (payload) => dispatch(getAccountID(payload)),
        getPaymentReference: (id, memberId) => dispatch(getPaymentReference(id, memberId)),
        getBalance: (id, memberId) => dispatch(getBalance(id, memberId)),
        getBalanceMocRes: (id) => dispatch(getBalanceMocRes(id)),
        generatePrn: (payload) => dispatch(generatePrn(payload)),
        postBalanceMoc: (payload, id, accountUuid) => dispatch(postBalanceMoc(payload, id, accountUuid)),
        deleteBalanceMoc: (id) => dispatch(deleteBalanceMoc(id)),
        balancePost: (id, payload) => dispatch(balancePost(id, payload)),
        updateBalanceMoc: (prn, payload) => dispatch(updateBalanceMoc(prn, payload)),
    };
};

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpiBalance);