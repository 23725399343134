import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Divider,
    Tab,
    Tabs,
    AppBar,
    Grid,
    Tooltip,
    Box
} from "@material-ui/core";

import Swal from "sweetalert2";
import moment from "moment";
import { displayLoading, displayErrorNew } from "../../common/alerts/alerts";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Button, Typography, TextField, Select, MenuItem, FormControl, FormControlLabel, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/lab';
import Alert from '@mui/material/Alert';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DynamicTable from './DynamicTable';
import IconRoundedButton from './IconRoundedButton';
import ContentBody from "@library/core/surfaces/ContentBody";
import CreateIcon from '@mui/icons-material/Create';
import { CircularProgress } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles((theme) => ({
    bar: {
        minHeight: "60px",
        backgroundColor: "#ffffff",
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
    },
    activeIcon: (props) => ({
        color: "#3f51b5 !important",
    }),
    inactiveIcon: (props) => ({
        color: "#a8b0e1 !important",
    }),
    icon: (props) => ({
        fontSize: "2rem !important",
    }),
    text: {
        margin: '0px 10px',
        color: '#3f51b5',
        display: 'inline',
        width: '100%'
    },
    body: {
        transition: 'border-color 0.3s ease',
        '&:hover': {
            borderColor: '#212121',
        },
    },
    totalRecords: {
        margin: '0.6rem 15px -20px 0px',
        float: "right",
        fontSize: '15px',
    }
}));
const defaultNumber = 0;

const TabBar = (props) => {
    const { accountNumber, history, tabId = defaultNumber, tabIndex, className, tabs, setDynamicCustomer, dynamicCustomer, bvtType, setBvtType, setSalesforce, setPopupStatus, setCurrentTab, salesforceCreation, env, salesforceUnmsValidation, salesforceSimValidation, salesforceUpdate } = props;
    const classes = useStyles();

    //Form Handling Values
    const [showForm, setShowForm] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [open, setOpen] = useState(false);
    const [msisdnLoading, setMsisdnLoading] = useState("hide");
    const [simLoading, setSimLoading] = useState("hide");

    // Form Field Values
    const [formValues, setFormValues] = useState({
        type: 'Residential',
        firstName_c: '',
        middleName_c: '',
        lastName_c: '',
        firstName: '',
        lastName: '',
        birthdate: null,
        role: 'Account Owner',
        identity_type: '',
        passportNumber: '',
        countryCode: 'AUS',
        licenceNumber: "",
        cardNumber_d: "",
        stateOfIssue: '',
        effectiveDate: null,
        group: 'Mobile Group',
        plan: 'Essential',
        msisdn: '',
        simSerialNumber: ''
    });
    const [selectedRow, setSelectedRow] = useState(null); // Track selected row

    // Function to open the dialog
    const handleClickOpen = () => {
        setOpen(true);
    };

    // Function to close the dialog
    const handleClose = () => {
        setOpen(false);
    };

    // Function to handle confirmation action (you can add custom logic here)
    const handleConfirm = () => {
        // Add your back action logic here

        setSalesforce(null);
        props.setDialogStatus(null);
        setPopupStatus(false);
        setCurrentTab(0);
        setShowForm(true);
        setFormErrors({});
        setIsReadOnly(true);
        setFormValues({
            type: 'Residential',
            firstName_c: '',
            middleName_c: '',
            lastName_c: '',
            firstName: '',
            lastName: '',
            birthdate: null,
            role: 'Account Owner',
            identity_type: '',
            passportNumber: '',
            countryCode: 'AUS',
            licenceNumber: "",
            cardNumber_d: "",
            stateOfIssue: '',
            effectiveDate: null,
            group: 'Mobile Group',
            plan: 'Essential',
            msisdn: '',
            simSerialNumber: ''
        });
        setOpen(false); // Close the dialog
        setDynamicCustomer(null);
    };
    // Birthdate Validation
    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 19, today.getMonth(), today.getDate());

    const apiValidation = (type, data) => {
        if (type === "msisdn") {
            salesforceUnmsValidation(data, env).then((result) => {
                if (result?.data?.content.length > 0) {
                    if (result?.data?.content[0]?.statusName === "Available") {
                        setMsisdnLoading("success");
                        setFormErrors({ ...formErrors, msisdn: '' });
                    } else {
                        setMsisdnLoading("hide");
                        setFormErrors({ ...formErrors, msisdn: `The MSISDN status is ${result?.data?.content[0]?.statusName}. Please provide Available MSISDN` });
                    }
                } else {
                    setMsisdnLoading("hide");
                    setFormErrors({ ...formErrors, msisdn: 'MSISDN is not available' });
                }
            }).catch(() => {
                setFormErrors({ ...formErrors, msisdn: 'MSISDN is not available' });
            })
        } else {
            salesforceSimValidation({ "imsiStart": data, "imsiEnd": data, "environment": env }).then((result) => {
                if (result?.data?.message === "Success") {
                    if (result?.data?.resourceStatus.length > 0) {
                        if (result?.data?.resourceStatus[0]?.imsiStatus === "SIM_ALLOCATED" && result?.data?.resourceStatus[0]?.simStatus === "AVAILABLE") {
                            setSimLoading("success")
                            setFormErrors({ ...formErrors, simSerialNumber: '' });
                        } else {
                            setSimLoading("hide");
                            let errorMessage;
                            console.log(result?.data?.resourceStatus[0])
                            if (result?.data?.resourceStatus[0]?.imsiStatus === "SIM_ALLOCATED") {
                                errorMessage = `The SIM status is ${result?.data?.resourceStatus[0]?.simStatus}. Please provide Available SIM Number`;
                            } else if (result?.data?.resourceStatus[0]?.simStatus === "AVAILABLE") {
                                errorMessage = `The IMSI status is ${result?.data?.resourceStatus[0]?.imsiStatus}. Please provide valid SIM Number`;
                            } else {
                                errorMessage = `The IMSI status is ${result?.data?.resourceStatus[0]?.imsiStatus} and SIM status is ${result?.data?.resourceStatus[0]?.simStatus}. Please provide valid SIM Number`;
                            }
                            setFormErrors({ ...formErrors, simSerialNumber: errorMessage });
                        }
                    } else {
                        setSimLoading("hide")
                        setFormErrors({ ...formErrors, simSerialNumber: 'IMSI is not available' });
                    }
                } else {
                    setSimLoading("hide")
                    setFormErrors({ ...formErrors, simSerialNumber: result?.data?.message });
                }
            }).catch((error) => {

            })
        }
    }


    // Form Validation Start
    const validateField = (name, value) => {
        let errors = { ...formErrors };
        switch (name) {
            case 'firstName_c':
                errors.firstName_c = value ? '' : 'First Name is required';
                break;
            case 'lastName_c':
                errors.lastName_c = value ? '' : 'Last Name is required';
                break;
            case 'firstName':
                errors.firstName = value ? '' : 'First Name is required';
                break;
            case 'lastName':
                errors.lastName = value ? '' : 'Last Name is required';
                break;
            case 'middleName':
                errors.middleName = value ? '' : 'Middle Name is required';
                break;
            case 'birthdate':
                const age = moment().diff(moment(value), 'years');
                if (age < 18) {
                    errors.birthdate = 'You must be 18 years or older to proceed';
                } else {
                    errors.birthdate = value ? '' : 'Birthdate is required';
                }
                break;
            case 'identity_type':
                errors.identity_type = value ? '' : 'Identity Type is required';
                break;
            case 'licenceNumber':
                if (!/^[0-9]{10}$/.test(value)) {
                    errors.licenceNumber = 'Licence Number must be a number and It should be 10 digits';
                } else {
                    errors.licenceNumber = value ? '' : 'Licence Number is required';
                }
                break;
            case 'cardNumber_d':
                if (!/^[A-Z][0-9]{7}$/.test(value)) {
                    errors.cardNumber_d = 'Card Number must start with a letter followed by 7 digits. For Example.. (P0000006)';
                } else {
                    errors.cardNumber_d = value ? '' : 'Card Number is required';
                }
                break;
            case 'stateOfIssue':
                if (!/^[A-Za-z]+$/.test(value)) {
                    errors.stateOfIssue = 'State Of Issue must be a character';
                } else {
                    errors.stateOfIssue = value ? '' : 'State Of Issue is required';
                }
                break;
            case 'effectiveDate':
                errors.effectiveDate = value ? '' : 'Effective Date is required';
                break;
            case 'plan':
                errors.plan = value ? '' : 'Plan is required';
                break;
            case 'msisdn':
                if (!value) {
                    setMsisdnLoading("hide");
                    errors.msisdn = 'MSISDN is required';
                } else if (!/^61[0-9]{9}$/.test(value)) {
                    setMsisdnLoading("hide");
                    errors.msisdn = 'MSISDN must start with 61 and be 11 digits long';
                } else {
                    setMsisdnLoading("show");
                    // API validation
                    errors.msisdn = '';
                    apiValidation("msisdn", value);
                }
                break;
            case 'simSerialNumber':
                if (!value) {
                    setSimLoading("hide");
                    errors.simSerialNumber = 'SIM Serial Number is required';
                } else if (!/^[0-9]{20}$/.test(value)) {
                    setSimLoading("hide");
                    errors.simSerialNumber = 'SIM Serial Number must be a number and followed by 20 digits';
                } else {
                    setSimLoading("show");
                    // API validation
                    errors.simSerialNumber = '';
                    apiValidation("simSerialNumber", value);
                }
                break;
            case 'passportNumber':
                if (!value) {
                    errors.passportNumber = 'Passport Number is required';
                } else if (!/^[A-Z][0-9]{7}$/.test(value)) {
                    errors.passportNumber = 'Australian passport number must start with a letter followed by 7 digits. For Example.. (N1234567)';
                } else {
                    errors.passportNumber = '';
                }
            default:
                break;
        }
        setFormErrors(errors);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "firstName_c") {
            setFormValues({ ...formValues, [name]: value, firstName: value });
        } else if (name === "lastName_c") {
            setFormValues({ ...formValues, [name]: value, lastName: value });
        } else if (name === "middleName_c") {
            setFormValues({ ...formValues, [name]: value, middleName: value });
        }
        else {
            setFormValues({ ...formValues, [name]: value });
        }
        return validateField(name, value);
    };
    useEffect(() => {
        console.log(formValues)
    }, [formValues])

    const handleDateChange = (name, date) => {
        setFormValues({ ...formValues, [name]: date });
        validateField(name, date);
    };

    // Form Validation End

    // Next/Tab change Validation Handle start

    const validateStep = (paramTab = tabIndex) => {
        let errors = {};
        switch (paramTab) {
            case 0:
                if (!bvtType) errors.bvtType = 'Account Type is required';
                if (!formValues.firstName_c) errors.firstName_c = 'First Name is required';
                if (!isReadOnly) {
                    if (!formValues.lastName_c) errors.lastName_c = 'Last Name is required';
                }
                break;
            case 1:
                if (!formValues.firstName) errors.firstName = 'First Name is required';
                if (!formValues.lastName) errors.lastName = 'Last Name is required';
                if (!formValues.birthdate) errors.birthdate = 'Birthdate is required';
                if (!formValues.identity_type) errors.identity_type = 'Identity Type is required';
                if (formValues.identity_type === "Passport") {
                    if (!formValues.passportNumber) {
                        errors.passportNumber = 'Passport Number is required';
                    } else if (!/^[A-Z][0-9]{7}$/.test(formValues.passportNumber)) {
                        errors.passportNumber = 'Australian passport number must start with a letter followed by 7 digits. For Example.. (N1234567)';
                    }
                } if (formValues.identity_type === "DL") {
                    if (!formValues.licenceNumber) {
                        errors.licenceNumber = 'Licence Number is required';
                    } else if (!/^[0-9]{10}$/.test(formValues.licenceNumber)) {
                        errors.licenceNumber = 'Licence Number must be a number and It should be 10 digits';
                    } if (!formValues.cardNumber_d) {
                        errors.cardNumber_d = 'Card Number is required';
                    } if (!formValues.stateOfIssue) {
                        errors.stateOfIssue = 'State Of Issue is required';
                    }
                }
                break;
            case 2:
                if (!formErrors.msisdn) {
                    if (!formValues.msisdn) {
                        errors.msisdn = 'MSISDN is required';
                    } else if (!/^61[0-9]{9}$/.test(formValues.msisdn)) {
                        errors.msisdn = 'MSISDN must start with 61 and be 11 digits long';
                    }
                } else {
                    errors.msisdn = formErrors.msisdn;
                }
                if (!formValues.plan) {
                    errors.plan = 'Plan is required';
                }
                if (!formErrors.simSerialNumber) {
                    if (!formValues.simSerialNumber) {
                        errors.simSerialNumber = 'SIM Serial Number is required';
                    } else if (!/^[0-9]+$/.test(formValues.simSerialNumber)) {
                        errors.simSerialNumber = 'SIM Serial Number must be a number';
                    }
                } else {
                    errors.simSerialNumber = formErrors.simSerialNumber;
                }
                break;
            default:
                break;
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Next/Tab change Validation Handle End

    // Tab Handle start

    const handleTabChange = (event, newValue) => {
        props.tabUpdate(tabId || tabIndex)
    };

    useEffect(() => {
        if (tabId !== 0 && tabIndex !== 0) {
            props.tabUpdate(tabId || tabIndex)
        }
    }, [tabId]);

    // Tab Handle End

    // Form Handle Effect Start
    function generatelicenceNumber() {
        const randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
        return randomNumber.toString();
    }
    useEffect(() => {
        setFormErrors({ ...formErrors, bvtType: '' });
        if (bvtType === "BVT Test Account") {
            setIsReadOnly(true)
            setFormValues({ ...formValues, lastName: 'TEST', lastName_c: 'TEST', passportNumber: 'H3530677', licenceNumber: generatelicenceNumber(), cardNumber_d: 'D606071T', stateOfIssue: "NSW" });
        } else {
            setIsReadOnly(false);
            setFormValues({ ...formValues, lastName: '', lastName_c: '', passportNumber: '', licenceNumber: '', cardNumber_d: "", stateOfIssue: "" });
        }
    }, [bvtType]);




    // All The steps Form fields
    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                if (dynamicCustomer) {
                    const { Account } = dynamicCustomer;
                    const { fname, lname, mname, Type } = Account;
                    return <div disabled={!isReadOnly}>
                        <Grid item xs={12} textAlign="center">
                            {isReadOnly && <FormControl readonly>
                                <h3 style={{ marginLeft: "220px", width: "1200px" }}>Please select an Account Type</h3>
                                <RadioGroup
                                    style={{ marginLeft: "250px" }}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={"NON-BVT"}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 32,
                                        },
                                    }}
                                    required
                                >
                                    <FormControlLabel value="BVT Test Account" control={<Radio />} label="BVT Test Account" />
                                    <FormControlLabel value="NON-BVT" control={<Radio />} label="NON-BVT" />
                                </RadioGroup>
                                <br /><br />
                            </FormControl>}
                        </Grid>

                        <>
                            <FormControl fullWidth readonly required={isReadOnly}>
                                <InputLabel>Customer Type</InputLabel>
                                <Select
                                    name="type"
                                    value={"Residential"}
                                    disabled={!isReadOnly}
                                >
                                    <MenuItem value="Residential">Residential</MenuItem>
                                </Select>
                            </FormControl>
                            <br /><br />
                            <Grid item xs={12}>
                                <TextField
                                    label="First Name"
                                    name="firstName_c"
                                    value={fname}
                                    required={isReadOnly}
                                    fullWidth
                                    readonly
                                    disabled={!isReadOnly}
                                />
                            </Grid>
                            <br /><br />
                            <><TextField
                                label="Middle Name"
                                name="middleName_c"
                                value={mname || ""}
                                fullWidth
                                readonly
                                disabled={!isReadOnly}
                            /><br /><br /> </>

                            <TextField
                                label="Last Name"
                                name="lastName_c"
                                value={lname}
                                required={isReadOnly}
                                fullWidth
                                disabled={!isReadOnly}
                            />
                            <br /><br />
                        </>
                    </div>
                }
                return (
                    <>
                        <FormControl >
                            <h3 style={{ marginLeft: "220px", width: "1200px" }}>Please select an Account Type</h3>
                            <RadioGroup
                                style={{ marginLeft: "250px" }}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={bvtType}
                                onChange={(event) => {
                                    setBvtType(event.target.value);
                                    if (event.target.value === "BVT Test Account") {
                                        setFormErrors({ ...formErrors, lastName_c: '', lastName: '', passportNumber: '' });
                                    }
                                }}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 32,
                                    },
                                }}
                                required
                            >
                                <FormControlLabel value="BVT Test Account" control={<Radio />} label="BVT Test Account" />
                                <FormControlLabel value="NON-BVT" control={<Radio />} label="NON-BVT" />
                                {formErrors.bvtType && <Alert severity="error"><div style={{ marginTop: "2px" }}>{formErrors.bvtType}</div></Alert>}
                            </RadioGroup>

                        </FormControl>  <br /><br />
                        <FormControl fullWidth required error={!!formErrors.type}>
                            <InputLabel>Customer Type</InputLabel>
                            <Select
                                name="type"
                                value={formValues.type}
                                onChange={handleChange}
                            >
                                <MenuItem value="Residential">Residential</MenuItem>
                            </Select>
                            <FormHelperText>{formErrors.type}</FormHelperText>
                        </FormControl>
                        <br /><br />
                        <TextField
                            label="First Name"
                            name="firstName_c"
                            value={formValues.firstName_c}
                            onChange={handleChange}
                            required
                            fullWidth
                            error={!!formErrors.firstName_c}
                            helperText={formErrors.firstName_c}
                        />
                        <br /><br />
                        <TextField
                            label="Middle Name"
                            name="middleName_c"
                            value={formValues.middleName_c}
                            onChange={(e) => handleChange(e)}
                            fullWidth
                            readonly={bvtType === "BVT Test Account" && true}
                            error={!!formErrors.middleName_c}
                            helperText={formErrors.middleName_c}
                        />
                        <br /><br />
                        <TextField
                            label="Last Name"
                            name="lastName_c"
                            value={bvtType === "BVT Test Account" ? "TEST" : formValues.lastName_c}
                            onChange={(e) => {
                                if (!isReadOnly) {
                                    handleChange(e);
                                }
                            }}
                            required
                            fullWidth
                            error={!!formErrors.lastName_c}
                            helperText={formErrors.lastName_c}
                        />
                        <br /><br />
                    </>
                );
            case 1:
                if (dynamicCustomer) {
                    const { Contact } = dynamicCustomer;
                    return <DynamicTable contact={Contact} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
                } return <>
                    <TextField
                        label="First Name"
                        name="firstName"
                        value={formValues.firstName}
                        onChange={(e) => {
                            handleChange(e)
                        }}
                        fullWidth
                        error={!!formErrors.firstName}
                        helperText={formErrors.firstName}
                        disabled
                    />
                    <br /><br />
                    <TextField
                        label="Middle Name"
                        name="middleName"
                        value={formValues.middleName}
                        onChange={(e) => {
                            if (!isReadOnly) {
                                handleChange(e);
                            }
                        }}
                        fullWidth
                        error={!!formErrors.middleName}
                        helperText={formErrors.middleName}
                        disabled
                    />
                    <br /><br />
                    <TextField
                        label="Last Name"
                        name="lastName"
                        value={bvtType === "BVT Test Account" ? "TEST" : formValues.lastName}
                        onChange={(e) => {
                            if (!isReadOnly) {
                                handleChange(e);
                            }
                        }}
                        fullWidth
                        error={!!formErrors.lastName}
                        helperText={formErrors.lastName}
                        disabled
                    />
                    <br /><br />

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Birthdate"
                            value={formValues.birthdate}
                            maxDate={eighteenYearsAgo}
                            defaultCalendarMonth={eighteenYearsAgo}
                            onChange={(date) => handleDateChange('birthdate', date)}
                            inputFormat="dd/MM/yyyy"
                            renderInput={(params) => <TextField {...params} required fullWidth error={!!formErrors.birthdate} helperText={formErrors.birthdate} />}
                        />
                    </LocalizationProvider>
                    <br /><br />
                    <FormControl fullWidth required error={!!formErrors.role}>
                        <InputLabel>Role</InputLabel>
                        <Select
                            name="role"
                            value={formValues.role}
                            onChange={handleChange}
                        >
                            <MenuItem value="Account Owner">Account Owner</MenuItem>
                        </Select>
                        <FormHelperText>{formErrors.role}</FormHelperText>
                    </FormControl>
                    <br /><br />
                    <FormControl fullWidth required error={!!formErrors.identity_type}>
                        <InputLabel>Identity Type</InputLabel>
                        <Select
                            name="identity_type"
                            value={formValues.identity_type}
                            onChange={handleChange}
                        >
                            <MenuItem value="PASSPORT">Passport</MenuItem>
                            <MenuItem value="DL">Driving Licence</MenuItem>
                        </Select>
                        <FormHelperText>{formErrors.identity_type}</FormHelperText>
                    </FormControl>
                    <br /><br />
                    {formValues.identity_type === "PASSPORT" && <>
                        <TextField
                            required
                            label="Passport Number"
                            name="passportNumber"
                            onChange={(e) => {
                                if (bvtType === "NON-BVT") {
                                    handleChange(e);
                                }
                            }}
                            error={!!formErrors.passportNumber}
                            helperText={formErrors.passportNumber}
                            fullWidth
                            value={bvtType === "BVT Test Account" ? "H3530677" : formValues.passportNumber}
                            margin="normal"
                        />
                        <TextField
                            required
                            select
                            label="Country Code"
                            value={formValues.countryCode}
                            fullWidth
                            margin="normal"
                            readonly
                        >
                            <MenuItem value="AUS">AUS</MenuItem>
                        </TextField>
                    </>}
                    {(formValues.identity_type === "DL") && <>
                        <TextField
                            label="Licence Number"
                            name="licenceNumber"
                            value={formValues.licenceNumber}
                            onChange={(e) => {
                                if (bvtType === "NON-BVT") {
                                    handleChange(e);
                                }
                            }}
                            error={!!formErrors.licenceNumber}
                            helperText={formErrors.licenceNumber}
                            required
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Card Number"
                            name="cardNumber_d"
                            value={formValues.cardNumber_d}
                            onChange={(e) => {
                                if (bvtType === "NON-BVT") {
                                    handleChange(e);
                                }
                            }}
                            error={!!formErrors.cardNumber_d}
                            helperText={formErrors.cardNumber_d}
                            required
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="State Of Issue"
                            name="stateOfIssue"
                            value={formValues.stateOfIssue}
                            onChange={(e) => {
                                if (bvtType === "NON-BVT") {
                                    handleChange(e);
                                }
                            }} error={!!formErrors.stateOfIssue}
                            helperText={formErrors.stateOfIssue}
                            required
                            fullWidth
                            margin="normal"
                        />
                    </>}
                </>
            case 2:
                return (
                    <>
                        <FormControl fullWidth required error={!!formErrors.group}>
                            <InputLabel>Group</InputLabel>
                            <Select
                                name="group"
                                value={formValues.group}
                                onChange={handleChange}
                            >
                                <MenuItem value="Mobile Group">Mobile Group</MenuItem>
                                <MenuItem value="Other Group">Other Group</MenuItem>
                            </Select>
                            <FormHelperText>{formErrors.group}</FormHelperText>
                        </FormControl>
                        <br /><br />
                        <FormControl fullWidth required error={!!formErrors.plan}>
                            <InputLabel>Plan</InputLabel>
                            <Select
                                name="plan"
                                value={formValues.plan}
                                onChange={handleChange}
                            >
                                {['Starter', 'Basic', 'Essential', 'Premium'].map((plan) => (
                                    <MenuItem key={plan} value={plan}>{plan}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{formErrors.plan}</FormHelperText>
                        </FormControl>
                        <br /><br />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            label="MSISDN"
                            name="msisdn"
                            placeholder='Provide MSISDN with Available Status'
                            value={formValues.msisdn}
                            onChange={handleChange}
                            required
                            fullWidth
                            InputProps={{
                                endAdornment: msisdnLoading === "success" ? <CheckCircleIcon sx={{ color: "green" }} size={24} /> : msisdnLoading === "show" ? <CircularProgress size={24} /> : null,
                                dataShrink: true
                            }}
                            error={!!formErrors.msisdn}
                            helperText={formErrors.msisdn
                            }
                            inputProps={{ pattern: '^61[0-9]{9}$', title: 'Must start with 61 and be 11 digits long' }}
                            data
                        />
                        <br /><br />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            label="SIM Serial Number"
                            name="simSerialNumber"
                            placeholder={`Please SIM with Available Status and IMSI with SIM_Allocated Status`}
                            value={formValues.simSerialNumber}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: simLoading === "success" ? <CheckCircleIcon sx={{ color: "green" }} size={24} /> : simLoading === "show" ? <CircularProgress size={24} /> : null
                            }}
                            required
                            fullWidth
                            error={!!formErrors.simSerialNumber}
                            helperText={formErrors.simSerialNumber}
                        />
                        <br /><br />
                    </>
                );
            default:
                return 'Unknown step';
        }
    };

    const decodeName = (fullName) => {
        const parts = fullName.trim().split(' ');
        let firstName = parts[0] || '';
        let middleName = '';
        let lastName = '';
        if (parts.length === 3) {
            middleName = parts[1];
            lastName = parts[2];
        } else if (parts.length === 2) {
            lastName = parts[1];
        }
        return {
            fname: firstName,
            mname: middleName,
            lname: lastName
        }
    }

    const salesforceCreationApi = (env, payload) => {
        displayLoading({ title: "Updating Held Order", bodyText: "Please standby" });
        let range = { "startRange": formValues?.msisdn, "endRange": formValues?.msisdn }
        let slUpdatepayload = {
            type: "Held Order",
            range,
            resourceType: "MSISDN",
            environment: env,
            resourcePoolName: "UNMS Postpaid MSISDN"
        }
        salesforceUpdate(slUpdatepayload).then((res) => {
            Swal.fire({
                type: "success",
                icon: 'success',
                title: "Held Order status Successfully updated",
                showCancelButton: false,
            });
            if (res?.data?.status === "Success") {
                // salesforceUpdate
                displayLoading({ title: "submitting Salesforce data", bodyText: "Please standby" });

                salesforceCreation(env, payload).then((result) => {
                    if (result?.responseStatus === "Fail") {
                        return Swal.fire("Failed", result?.responseDescription, 'error');
                    }
                    if (result?.status === 200) {
                        Swal.fire({
                            type: "success",
                            icon: 'success',
                            title: result?.data,
                            showCancelButton: false,
                        }).then(() => {
                            handleConfirm();
                            history.push('/data/utility/summaryPage')
                        });
                    }
                }).catch((error) => {
                    if (error?.responseStatus === "Fail") {
                        Swal.fire("Failed", error?.responseDescription, 'error');
                    }
                });
            }
            if (res?.responseStatus === "Fail") {
                Swal.fire("Failed", res?.responseDescription, 'error');
            }
        }).catch((error) => {
            if (error?.responseStatus === "Fail") {
                Swal.fire("Failed", error?.responseDescription, 'error');
            }
        })
    }

    // Tab Change handle start
    const handleNext = () => {
        let { group, plan, simSerialNumber, msisdn, type, firstName_c, middleName_c, lastName_c, firstName, lastName, birthdate, identity_type, passportNumber, licenceNumber, stateOfIssue, cardNumber_d } = formValues;
        if (dynamicCustomer && (tabIndex === 0 || tabIndex === 1)) {
            props.tabUpdate(tabIndex + 1);
        }
        if (validateStep()) {

            if (tabIndex === tabs.length - 1) {
                const cases = [0, 1, 2, 3];  // Values to test in switch
                const results = [];  // Array to store all the results

                // Loop through each case and collect the results
                cases.forEach(val => {
                    const result = validateStep(val);
                    results.push(result);  // Add the result object to the array
                });
                let payload;
                let document;
                if (identity_type === "PASSPORT") {
                    document = {
                        "identityType": identity_type,
                        "identityNumber": passportNumber,
                        "countryCode": "AUS"
                    }
                } else {
                    document = {
                        "identityType": identity_type,
                        "identityNumber": cardNumber_d,
                        stateOfIssue,
                        licenceCardNumber: licenceNumber
                    }
                }
                // {
                //     "customerId": null,
                // "planName": "Essentials",
                //     "group": "Mobile Group",
                //     "accountType": "NON-BVT",
                //     "sim": "89610183748995743907",
                //     "msisdn": "61475127538",
                //     "account": {
                //         "firstName": "SARTRTOTRNNZRKOUT",
                //         "middleName": "TEST",
                //         "lastName": "SARTRTOTRNNZRKOUT",
                //         "Type": "Residential"
                //     },
                //     "contact": {
                //         "LastName": "SARTRTOTRNNZRKOUT",
                //         "FirstName": "SWERTOTRNNZRKOUT",
                //         "Birthdate": "2000-05-16",
                //         "document": {
                //             "identityType": "PASSPORT",
                //             "identityNumber": "U1243902",
                //             "countryCode": "AUS",
                //             "stateOfIssue": "",
                //             "licenceCardNumber": ""
                //         }
                //     }
                // }
                if (results.reduce((sum, value) => sum + (value ? 1 : 0), 0) === results.length) {
                    if (isReadOnly) {
                        payload = {
                            "customerId": null,
                            "planName": plan,
                            "group": group,
                            "accountType": bvtType,
                            "sim": simSerialNumber,
                            msisdn,
                            "account": {
                                "firstName": firstName_c,
                                "middleName": middleName_c,
                                "lastName": lastName_c,
                                "Type": type
                            },
                            "contact": {
                                "LastName": lastName,
                                "FirstName": firstName,
                                "Birthdate": moment(birthdate).format("YYYY-MM-DD"),
                                document
                            }
                        }
                    } else {
                        payload = {
                            "customerId": null,
                            "planName": plan,
                            "group": group,
                            "accountType": bvtType,
                            "sim": simSerialNumber,
                            msisdn,
                            "account": {
                                "firstName": firstName_c,
                                "middleName": middleName_c,
                                "lastName": lastName_c,
                                "Type": type
                            },
                            "contact": {
                                "LastName": lastName,
                                "FirstName": firstName,
                                "Birthdate": moment(birthdate).format("YYYY-MM-DD"),
                                document
                            }
                        }
                    }
                    salesforceCreationApi(env, payload);
                } else {
                    if (dynamicCustomer) {
                        let { Account, Contact } = dynamicCustomer;
                        const { Type } = Account;
                        let { simSerialNumber, msisdn } = formValues;
                        const { fname, mname, lname } = decodeName(Account.Name)
                        const row = Contact[selectedRow];
                        payload = {
                            customerId: accountNumber,
                            "planName": plan,
                            "group": group,
                            "accountType": bvtType,
                            "sim": simSerialNumber,
                            msisdn,
                            "account": {
                                "firstName": fname,
                                "middleName": mname,
                                "lastName": lname,
                                Type
                            },
                            "contact": {
                                "LastName": row?.LastName,
                                "FirstName": row?.FirstName,
                                "Birthdate": row?.Birthdate,
                                "role": row?.role
                            }
                        }
                        salesforceCreationApi(env, payload);
                    } else {
                        salesforceCreationApi(env, payload);
                        displayErrorNew({
                            title: "Validation Error",
                            subTitle: "Please check all the section and fill the details",
                        })
                    }
                }
            } else {
                props.tabUpdate(tabIndex + 1);
            }
        }
    };

    const handleBack = () => {
        props.tabUpdate((prevActiveStep) => prevActiveStep - 1);
    };

    // Tab Change handle End

    const resetCurrentTab = () => {
        let obj;
        if (0 === tabIndex) {
            obj = {
                type: 'Residential',
                firstName_c: '',
                middleName_c: '',
                lastName_c: '',
                firstName: '',
                lastName: '',
                middleName: ''
            }
            setBvtType(null);
        } else if (1 === tabIndex) {
            obj = {
                birthdate: null,
                role: 'Account Owner',
                identity_type: '',
                passportNumber: '',
                countryCode: 'AUS',
                licenceNumber: "",
                cardNumber_d: "",
                stateOfIssue: '',
            }
        } else if (2 === tabIndex) {
            obj = {
                group: 'Mobile Group',
                plan: 'Essential',
                msisdn: '',
                simSerialNumber: ''
            }
        }
        setFormValues({ ...formValues, ...obj })
    }

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div className={classes.root}>
            <AppBar elevation={0} position="static" color="default" className={`${classes.bar} ${className}`}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs"
                    style={{ minHeight: "60px" }}
                >
                    {
                        props.tabs.map((tab, index) => {
                            return (
                                <Tab key={index} icon={tab.icon} label={tab.name} onClick={() => props.tabUpdate(tab.id || index)} style={{ minHeight: "60px" }} />
                            );
                        })
                    }
                </Tabs>
                <Divider />
                <ContentBody
                    marginBottom={2}
                    padding={3}
                    borderTopLeft="0px"
                    borderTopRight="0px"
                    disableTopBorder
                >
                    <Grid container direction="row" justifyContent="center">
                        <Grid item sm={12}>
                            <Box
                                display="inline-flex"
                                alignItems="center"
                                border="1px solid rgba(0, 0, 0, 0.23)"
                                borderRadius="4px"
                                padding="12px"
                                minHeight="36px"
                                width="100%"
                                boxShadow={0}
                                className={classes.body}
                            >
                                <CreateIcon className={`${classes.icon} ${classes.activeIcon}`} />
                                <Typography
                                    variant="body1"
                                    className={classes.text}
                                    style={{ marginLeft: "20px" }}
                                >
                                    {dynamicCustomer ? <p>{capitalizeFirstLetter(tabs[tabIndex]?.name)} Details</p> : <p>Creating Order In <b>{bvtType}</b> Flow. Please choose BVT type</p>}
                                </Typography>
                                <div style={{ marginLeft: "140px" }}>{!(dynamicCustomer && (tabIndex === 0 || tabIndex === 1)) && < IconRoundedButton resetCurrentTab={resetCurrentTab} />}</div>
                            </Box>
                        </Grid>
                    </Grid>
                </ContentBody>
                <br />
                <div className='formData' style={{ width: "900px", marginLeft: "140px" }}>
                    {renderStepContent(tabIndex)}
                </div>
            </AppBar>
            <Dialog open={open} onClose={handleClose}
                BackdropProps={{
                    style: {
                        backdropFilter: 'blur(5px)', // Apply blur to the background
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add a semi-transparent background
                    },
                }}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <p>If you go back now, all form fields will be reset. Do you want to continue?</p>
                </DialogContent>
                <DialogActions>
                    {/* Cancel Button */}
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    {/* Confirm Button */}
                    <Button onClick={handleConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <>
                <br />
                <Grid item xs={12} textAlign="center">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!tabIndex}
                        onClick={handleBack}  >
                        Previous
                    </Button>
                    <Button
                        style={{ marginLeft: "78%" }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={msisdnLoading === "show" || simLoading === "show"}
                    >
                        {tabIndex === tabs.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                </Grid>
            </>
        </div>
    );
}

export default TabBar;
