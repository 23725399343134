import React, { useState } from "react";

import { Box, Accordion, AccordionDetails, AccordionSummary, Tooltip, Paper, Skeleton, CardContent, Card, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { green, red, grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';

const LoadingSkeleton = () => {
    return (
        <Card variant="outlined" sx={{ maxWidth: 545, marginBottom: 2, marginLeft: "200px", marginTop: "20px" }}>
            <CardContent sx={{ marginLeft: "20px" }}>
                <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
                    <Skeleton variant="text" width={200} height={30} />
                    <Skeleton variant="text" width={160} height={10} />
                    <Skeleton variant="text" width={160} height={10} sx={{ marginBottom: 2 }} />
                    {/* Timeline skeleton */}
                    <Box sx={{ position: 'relative', marginBottom: 4 }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: '8px',
                                bottom: 0,
                                width: '2px',
                                backgroundColor: grey[300],
                                zIndex: 0,
                            }}
                        ></Box>

                        {/* Skeleton for each step */}
                        {[...Array(3)].map((_, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 3,
                                    position: 'relative',
                                    zIndex: 1,
                                }}
                            >
                                {/* Skeleton for timeline point */}
                                <Skeleton variant="circle" width={16} height={16} sx={{ position: 'absolute', left: '-8px', top: '20px', zIndex: 2 }} />

                                <Paper
                                    sx={{
                                        padding: 2,
                                        backgroundColor: grey[300] + '20',
                                        borderLeft: `4px solid ${grey[500]}`,
                                        paddingLeft: 3,
                                        width: '100%',
                                    }}
                                    elevation={3}
                                >
                                    <Skeleton variant="text" width="80%" height={20} sx={{ marginBottom: 1 }} />
                                    <Skeleton variant="text" width="60%" height={20} sx={{ marginBottom: 1 }} />
                                    <Skeleton variant="text" width="50%" height={20} sx={{ marginBottom: 1 }} />
                                </Paper>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};
const Timeline = ({ data, loading, resetForm, getDataSummary, requestID }) => {
    const [openDialog, setOpenDialog] = useState(false);
    let getRecentStatus = data?.steps[data?.steps.length - 1];
    let checkErrorStatus = data?.steps.every((_item) => _item.status === "SUCCESS");
    const getStepColor = (status) => {
        if (status === 'SUCCESS' || status === 'Success') return green[500];
        if (status === 'FAILED' || status === 'Failure') return red[500];
        return grey[500]; // for any undefined status
    };
    const handleReset = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmReset = () => {
        setOpenDialog(false); // Close dialog
        resetForm();
    };

    const checkProgress = (e, expand) => {
        if (expand) {
            setTimeout(() => {
                getDataSummary(requestID);
            }, 30000);
        }
    }

    return (
        <>
            {data && <Card variant="outlined" sx={{ maxWidth: 765, marginBottom: 2, marginLeft: "120px", marginTop: "20px" }}>
                <CardContent sx={{ marginLeft: "20px" }}>
                    {data && <Box sx={{ margin: 'auto', padding: 2 }}>
                        <Typography variant="h6" sx={{ fontSize: "18px" }} color="text.primary">Customer ID: {data.customerId}</Typography>
                        <Typography variant="body2" color="text.secondary">Account Type: {data.accountType || 'N/A'}</Typography>
                        <Typography variant="body2" color="text.secondary">ID: {data.id || 'N/A'}</Typography> <br />
                        {/* Timeline container */}
                        <Box sx={{ position: 'relative', marginBottom: 4 }}>
                            {/* Vertical line */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: '8px',
                                    bottom: 0,
                                    width: '2px',
                                    zIndex: 0,
                                }}
                            ></Box>
                            <Box
                                key={0}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 3,
                                    position: 'relative',
                                    zIndex: 1,
                                }}
                            >
                                <Paper
                                    sx={{
                                        padding: 2,
                                        backgroundColor: `${getStepColor(getRecentStatus.status)}20`,
                                        borderLeft: `4px solid ${getStepColor(getRecentStatus.status)}`,
                                        paddingLeft: 3,
                                        width: '100%',
                                    }}
                                    elevation={3}
                                >
                                    <Typography variant="h6" sx={{ fontSize: "18px" }}>Latest Status: {getRecentStatus.creationId}</Typography>
                                </Paper>
                            </Box>
                            {/* Steps (timeline points) */}
                            <Accordion onChange={checkProgress}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{ height: "45px" }}
                                >
                                    <Typography component="span">Process Overview</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {data.steps.map((step, index) => {
                                        const stepColor = getStepColor(step.status);

                                        return (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    marginBottom: 3,
                                                    position: 'relative',
                                                    zIndex: 1,
                                                }}
                                            >
                                                <Paper
                                                    sx={{
                                                        padding: 2,
                                                        backgroundColor: stepColor + '20', // Lightened background color
                                                        borderLeft: `4px solid ${stepColor}`,
                                                        paddingLeft: 3,
                                                        width: '100%',
                                                    }}
                                                    elevation={3}
                                                >
                                                    <Typography variant="h6" sx={{ fontSize: "18px" }}>Step {index + 1}: {step.creationId}</Typography>
                                                </Paper>
                                            </Box>
                                        );
                                    })}
                                    {data.steps.length < 10 && checkErrorStatus && <Box
                                        // key={index}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginBottom: 3,
                                            position: 'relative',
                                            zIndex: 1,
                                            width: "450px",
                                            marginLeft: "80px"
                                        }}
                                    >
                                        <Paper
                                            sx={{
                                                padding: 2,
                                                backgroundColor: `#fffbaf`,
                                                borderLeft: `4px solid #ffc107`,
                                                paddingLeft: 3,
                                                width: '100%',
                                            }}
                                            elevation={3}
                                        >
                                            <Typography variant="h6" sx={{ fontSize: "18px", marginLeft: "80px" }}>In Progress</Typography>
                                            <Box sx={{ width: '100%', marginTop: "20px" }}>
                                                <LinearProgress />
                                            </Box>
                                        </Paper>
                                    </Box>}

                                </AccordionDetails>
                            </Accordion>

                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReset}
                            sx={{ padding: '8px 16px', marginLeft: "595px" }}
                        >
                            Reset
                        </Button>
                    </Box>}

                </CardContent>
            </Card>}

            {loading && <LoadingSkeleton />}
            <Dialog open={openDialog} onClose={handleCloseDialog} BackdropProps={{
                style: {
                    backdropFilter: 'blur(5px)', // Apply blur to the background
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add a semi-transparent background
                },
            }}>
                <DialogTitle>Reset Confirmation</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to reset the page?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmReset} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Timeline;