import { axiosInstance as axios } from "../axios.config";
const axiosAPI = require('axios');
import * as types from './actionTypes';
import config from '../config';
import { authHeaders } from '../utils/api';

const API_URL_GATEWAY = config.tdm_gateway_api.uri;
const EPIC_API_URL = config.tdm_epic_gateway_api.uri + '/tdm';

export const salesforceAccountNumber = (env, accountNumber) => async () => {
    try {
        const response = await axios.get(`${API_URL_GATEWAY}/tdm/salesforce/b2c/account/${accountNumber}/search?environment=${env}`, { headers: authHeaders() });
        return response;
    } catch (e) {
        let message;
        if (!e.response) {
            message = "Network connectivity issues, please try again later.";
        } else {
            message = e.response.data.responseDescription;
        }
        let errorRes = {
            responseDescription: message,
            responseStatus: "Fail",
            status: "INTERNAL_SERVER_ERROR",
        }
        return errorRes;
    }
}

export const salesforceCreation = (env, payload) => async (dispatch) => {
    try {
        const response = await axios.post(`${API_URL_GATEWAY}/tdm/salesforce/b2c/process?env=${env}`, payload, { headers: authHeaders() });
        dispatch({ type: types.SALESFORCE_CREATION, payload: response.data });
        return response;
    } catch (e) {
        let message;
        if (!e.response) {
            message = "Network connectivity issues, please try again later.";
        } else {
            message = e.response.data.responseDescription;
        }
        let errorRes = {
            responseDescription: message,
            responseStatus: "Fail",
            status: "INTERNAL_SERVER_ERROR",
        }
        return errorRes;
    }
}

export const salesforceDataSummary = (payload) => async (dispatch) => {
    try {
        const response = await axios.get(`${API_URL_GATEWAY}/tdm/salesforce/b2c/process/status?requestId=${payload.requestID}`, { headers: authHeaders() });
        dispatch({ type: types.SALESFORCE_CREATION, payload: null });
        return response;
    } catch (e) {
        let message;
        if (!e.response) {
            message = "Network connectivity issues, please try again later.";
        } else {
            message = e.response.data.responseDescription;
        }
        let errorRes = {
            responseDescription: message,
            responseStatus: "Fail",
            status: "INTERNAL_SERVER_ERROR",
        }
        dispatch({ type: types.SALESFORCE_CREATION, payload: null });
        return errorRes;
    }
}

export const salesforceUnmsValidation = (payload, INT2) => async () => {
    try {
        const response = await axios.get(`${API_URL_GATEWAY}/tdm/unms/resources?startRange=${payload}&endRange=${payload}&page=0&size=6&environment=${INT2}`, { headers: authHeaders() });
        return response;
    } catch (e) {
        let message;
        if (!e.response) {
            message = "Network connectivity issues, please try again later.";
        } else {
            message = e.response.data.responseDescription;
        }
        let errorRes = {
            responseDescription: message,
            responseStatus: "Fail",
            status: "INTERNAL_SERVER_ERROR",
        }
        return errorRes;
    }
}

export const salesforceSimValidation = (payload) => async () => {
    try {
        const response = await axios.post(`${API_URL_GATEWAY}/tdm/unms/simas/fetch`, payload, { headers: authHeaders() });
        return response;
    } catch (e) {
        let message;
        if (!e.response) {
            message = "Network connectivity issues, please try again later.";
        } else {
            message = e.response.data.responseDescription;
        }
        let errorRes = {
            responseDescription: message,
            responseStatus: "Fail",
            status: "INTERNAL_SERVER_ERROR",
        }
        return errorRes;
    }
}

export const salesforceUpdate = (payload) => async () => {
    try {
        const response = await axiosAPI.put(`${API_URL_GATEWAY}/tdm/unms/resource/status/range`, payload, { headers: authHeaders() });
        return response;
    } catch (e) {
        let message;
        if (!e.response) {
            message = "Network connectivity issues, please try again later.";
        } else {
            message = e.response.data.responseDescription;
        }
        let errorRes = {
            responseDescription: message,
            responseStatus: "Fail",
            status: "INTERNAL_SERVER_ERROR",
        }
        return errorRes;
    }
}

export const customerCreationAction = (env,payload) => async () => {
    try {
        const response = await axiosAPI.post(`${API_URL_GATEWAY}/tdm/salesforce/b2c/customer/util?env=${env}`, payload, { headers: authHeaders() });
        return response;
    } catch (e) {
        let message;
        if (!e.response) {
            message = "Network connectivity issues, please try again later.";
        } else {
            message = e.response.data.responseDescription;
        }
        let errorRes = {
            responseDescription: message,
            responseStatus: "Fail",
            status: "INTERNAL_SERVER_ERROR",
        }
        return errorRes;
    }
}