import React, { useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, Checkbox
} from '@mui/material';

function DynamicTable({contact,selectedRow,setSelectedRow}) {
    // State for pagination and selected row
    const [page, setPage] = useState(1); // Track current page (1-based index)

    const rowsPerPage = 5;
    
    // Handle pagination change
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    // Handle row selection
    const handleRowSelect = (rowIndex) => {
        setSelectedRow(rowIndex === selectedRow ? null : rowIndex);  // Toggle selection
    };

    // Get the current page data
    const currentRows = contact.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <div>
            <TableContainer component={Paper} style={{"width":"1250px",marginLeft:"-130px"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Choose</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Middle Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Mobile Phone</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Birth Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentRows.map((row, index) => (
                            <TableRow
                                key={index}
                                hover
                                selected={selectedRow === index}
                                onClick={() => handleRowSelect(index)}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox checked={selectedRow === index} onChange={() => handleRowSelect(index)} />
                                </TableCell>
                                <TableCell>{row.FirstName}</TableCell>
                                <TableCell>{row.MiddleName}</TableCell>
                                <TableCell>{row.LastName}</TableCell>
                                <TableCell>{row.MobilePhone}</TableCell>
                                <TableCell>{row.Email}</TableCell>
                                <TableCell>{row.vlocity_cmt__Status__c}</TableCell>
                                <TableCell>{row.role}</TableCell>
                                <TableCell>{row.Birthdate}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            
            {/* Pagination */}
            {rowsPerPage > 5 && <Pagination
                count={Math.ceil(contact.length / rowsPerPage)} // Total number of pages
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{ marginTop: 2 }}
            /> }
             <br/>
        </div>
    );
}

export default DynamicTable;
