import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Radio, RadioGroup, FormControlLabel, Button, Typography, TextField } from '@mui/material';
import { useEffect } from 'react';
import { displayLoading, displayErrorNew, displaySuccess } from "../../common/alerts/alerts";
import swal from "sweetalert2";

const PaymentModal = ({ open, handleClose, BalanceMocResult, updateBalanceMoc, postBalanceMoc, typeOfAction, accountuuid }) => {

    const paymentReferences = BalanceMocResult?.data?.paymentReferences;
    const [selectedPaymentReference, setSelectedPaymentReference] = useState('');
    const [payloadData, setPayloadData] = useState(null);
    const [isValidJson, setIsValidJson] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const handleRadioChange = (event) => {
        setSelectedPaymentReference(event.target.value);
    };

    useEffect(() => {
        if (selectedPaymentReference) {
            let getPayload = paymentReferences.find((_item) => {
                return _item?.paymentReferenceNumber == selectedPaymentReference
            });
            setPayloadData(JSON.stringify(getPayload, null, 2))
        }
    }, [selectedPaymentReference])

    const handleSubmit = () => {
        if (selectedPaymentReference) {
            if (typeOfAction === "update") {
                displayLoading({
                    title: "Triggering Update Service",
                    bodyText: "Please standby processing data",
                });
                updateBalanceMoc(selectedPaymentReference, JSON.parse(payloadData)).then((result) => {
                    swal.close();
                    displaySuccess({
                        title: result?.data?.status,
                        text: result?.data?.info,
                    }).then(() => {

                    })
                });
                handleClose(); // Close modal after submission
            } else {
                displayLoading({
                    title: "Triggering Post Service",
                    bodyText: "Please standby processing data",
                });
                let parseJson = JSON.parse(payloadData);

                postBalanceMoc(parseJson, selectedPaymentReference, accountuuid).then((result) => {
                    swal.close();
                    if (result?.data?.status === "error") {
                        displayErrorNew({
                            title: "error",
                            text: result?.data?.info,
                        })
                    } else {
                        displaySuccess({
                            title: result?.data?.status,
                            text: result?.data?.info,
                            errorMsg: result?.data?.description
                        })
                    }
                    // displaySuccess({
                    //     title: result?.data?.reason,
                    //     text: result?.data?.description,
                    // }).then(() => {

                    // })
                });
                handleClose(); // Close modal after submission
            }
        }
    };

    const handleInputChange = (e) => {
        try {

            JSON.parse(e.target.value); // Try parsing the JSON input
            setIsValidJson(true);
            setErrorMessage('');
        } catch (error) {
            setIsValidJson(false);
            setErrorMessage('Invalid JSON format');
        }
        setPayloadData(e?.target?.value)
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h6" color="primary" align="center">
                    Choose a Payment Reference Number
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Please select one of the following payment reference numbers:
                </Typography>
                <RadioGroup value={selectedPaymentReference} onChange={handleRadioChange}>
                    {paymentReferences?.map((refNumber) => (
                        <FormControlLabel
                            key={refNumber?.paymentReferenceNumber}
                            value={refNumber?.paymentReferenceNumber}
                            control={<Radio color="primary" />}
                            label={<Typography variant="body2">{refNumber?.paymentReferenceNumber}</Typography>}
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
            {payloadData && <>
                <TextField
                    label="Enter JSON"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={9}
                    value={payloadData}
                    onChange={handleInputChange}
                    // error={!validJson}
                    // helperText={error}
                    sx={{
                        width: "400px",
                        marginLeft: "110px"
                    }}
                    helperText={!isValidJson && errorMessage}
                    error={!isValidJson}
                /> <br />
            </>}
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    disabled={!selectedPaymentReference || !isValidJson}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentModal;
