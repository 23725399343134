import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, CircularProgress, MenuItem, Select, FormControl, InputLabel, Button, FormHelperText, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactJson from 'react-json-view';
import { connect } from "react-redux";
import { templateOptions } from './const';
import { getCarbonEcom, getCarbonAgent, getCarbonMocResponse, getSqiPayload, carbonAddBill, carbonDeleteBill } from "../../../actions/sqiActions"
import { displayLoading, displayErrorNew } from "../../common/alerts/alerts";
import swal from "sweetalert2";

const SqiCarbon = (props) => {
    const { getCarbonEcom, getCarbonAgent, getCarbonMocResponse, getSqiPayload, carbonAddBill, carbonDeleteBill } = props;
    const [loading, setLoading] = useState(false);
    const [showJson, setshowJson] = useState(false);
    const [openInputJson, setOpenInputJson] = useState(false); // For showing success message popup
    const [jsonData, setJsonData] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // For delete confirmation dialog
    const [carbonEcomRes, setCarbonEcomRes] = useState(null);
    const [carbonAgentRes, setCarbonAgentRes] = useState(null);
    const [carbonMocResponse, setCarbonMocResponse] = useState(null);
    const [formData, setFormData] = useState({
        orderId: '',
        serviceId: '',
        template: '',
    });
    const [errors, setErrors] = useState({
        orderId: '',
        serviceId: '',
        template: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Validation logic
        if (name === 'orderId') {
            if (!/^[A-Za-z]\d{14}$/.test(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    orderId: 'Order ID must start with a letter followed by 15 digits.',
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    orderId: '',
                }));
            }
        }

        if (name === 'serviceId') {
            if (!/^\d{11}$/.test(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    serviceId: 'Service ID must be exactly 11 digits.',
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    serviceId: '',
                }));
            }
        }

        if (name === 'template') {
            if (value === '') {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    template: 'Template is required.',
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    template: '',
                }));
            }
        }
    };

    useEffect(() => {
        if (openInputJson) {
            setJsonData(JSON.stringify(jsonData, null, 2))
        }
    }, [openInputJson])

    useEffect(() => {
        if (carbonEcomRes && carbonAgentRes && carbonMocResponse) {
            setLoading(false);
            setshowJson(true);
        }
    }, [carbonEcomRes, carbonAgentRes, carbonMocResponse]);

    const handleSubmit = () => {
        const { orderId, serviceId, template } = formData;
        // Check if there are any errors before submitting the form
        const isFormValid = Object.values(errors).every((error) => error === '') &&
            Object.values(formData).every((field) => field !== '');
        if (isFormValid) {
            displayLoading({
                title: "Triggering TDM Service",
                bodyText: "Please standby Fetching Payload",
            });
            let payload = {
                orderId,
                serviceId,
                "templateName": template,
                "environment": "SQNI"
            }
            let errorMsg = { Info: `No records found `, Error: "404" }

            getSqiPayload(payload).then((response) => {
                swal.close();
                if (response) {
                    if (response.status === 200) {
                        let jsonDataRes = response?.data;
                        setJsonData(jsonDataRes);
                        setLoading(true);
                        let res1 = getCarbonEcom(formData?.serviceId, jsonDataRes.accountUUID);
                        let res2 = getCarbonAgent(formData?.serviceId, jsonDataRes.accountUUID, jsonDataRes.customerId);
                        let res3 = getCarbonMocResponse(jsonDataRes.accountUUID);
                        res1.then((result) => {
                            if (result?.data) {
                                setCarbonEcomRes(result?.data);
                            } else {
                                setCarbonEcomRes({ Info: result, Error: "500" });
                            }
                        }).catch((error) => {
                            setCarbonEcomRes(errorMsg);
                        });
                        res2.then((result) => {
                            if (result?.data) {
                                setCarbonAgentRes(result.data);
                            } else {
                                setCarbonAgentRes({ Info: result, Error: "500" });
                            }
                        }).catch((error) => {
                            setCarbonAgentRes(errorMsg);
                        });
                        res3.then((result) => {
                            if (result?.data) {
                                setCarbonMocResponse(result.data)
                            } else {
                                setCarbonMocResponse({ Info: result, Error: "500" });
                            }
                        }).catch((error) => {
                            setCarbonMocResponse(errorMsg);
                        });
                    } else {
                        displayErrorNew({
                            title: response?.responseStatus,
                            subTitle: response?.status,
                            errorMsg: response?.responseDescription
                        })
                    }
                }
            });

        } else {
            let error = {
                orderId: formData.orderId ? "" : "Order Id is required",
                serviceId: formData.serviceId ? "" : "Service Id is required",
                template: "Template is required",
            }
            setErrors(error);
        }
    };

    const handleDeleteAction = () => {
        setOpenDeleteDialog(true);
    };

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteConfirm = () => {
        setLoading(true);
        setOpenInputJson(false);
        setJsonData('')
        let { accountUUID } = jsonData;
        let result = carbonDeleteBill(accountUUID);
        result.then((res) => {
            handleSubmit();
            setOpenDeleteDialog(false)
        });
    };

    const handleReset = () => {
        setshowJson(false);
        setFormData({
            orderId: '',
            serviceId: '',
            template: '',
        })
        setErrors({
            orderId: '',
            serviceId: '',
            template: '',
        })
    }

    const handlePostAction = () => {
        setLoading(true);
        setOpenInputJson(false);
        setJsonData('')
        carbonAddBill(jsonData).then((res) => {
            handleSubmit();
        })
    };

    return (
        <>
            {!loading && !showJson && <form noValidate style={{ width: "500px", marginLeft: "135px" }} disabled={showJson}>
                <TextField
                    label="Order ID"
                    name="orderId"
                    value={formData.orderId}
                    onChange={handleChange}
                    fullWidth
                    required
                    error={!!errors.orderId}
                    helperText={errors.orderId}
                />
                <br /> <br />
                <TextField
                    label="Service ID"
                    name="serviceId"
                    value={formData.serviceId}
                    onChange={handleChange}
                    fullWidth
                    required
                    error={!!errors.serviceId}
                    helperText={errors.serviceId}
                /><br /> <br />
                <FormControl fullWidth required error={!!errors.template}>
                    <InputLabel>Template</InputLabel>
                    <Select
                        name="template"
                        value={formData.template}
                        onChange={handleChange}
                        label="Template"
                    >
                        {templateOptions.map((_item) => {
                            return <MenuItem key={_item} value={_item}>{_item}</MenuItem>
                        })}
                    </Select>
                    {errors.template && <FormHelperText>{errors.template}</FormHelperText>}
                </FormControl><br /> <br />
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={Object.values(errors).some((error) => error !== '')} style={{ marginLeft: "410px" }}>
                    Submit
                </Button>
            </form>}
            {!loading && showJson && <Box sx={{ marginBottom: 3, width: "500px", marginTop: "-90px" }}>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<ArrowBackIcon />}
                    onClick={handleReset}
                    sx={{ "marginTop": "95px", "marginLeft": "1160px" }}
                >
                    Back
                </Button>
            </Box>}
            {loading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress />
            </Box>}
            {!loading && showJson &&
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                padding: 2,
                                backgroundColor: 'rgb(228 226 226)',
                                borderRadius: 1,
                                minHeight: '200px',
                            }}
                        >
                            {/* Heading for the first column */}
                            <center>
                                <Typography variant="h5" sx={{ marginBottom: 2, fontSize: '1.8rem' }}>
                                    SQI - ECOM Response
                                </Typography>
                            </center>
                            <br />

                            {/* Render JSON data with 'tube' theme */}
                            <ReactJson src={carbonEcomRes} theme="ashes" style={{ overflow: "scroll", height: "275px" }} />
                            {/* Heading for the first column */}
                            <br />
                            <center>
                                <Typography variant="h5" sx={{ marginBottom: 2, fontSize: '1.8rem' }}>
                                    SQI - SF Response
                                </Typography>
                            </center>
                            <Box
                                sx={{
                                    padding: 2,
                                    backgroundColor: 'rgb(228 226 226)',
                                    borderRadius: 1,
                                    minHeight: '200px',
                                }}
                            >
                                {/* Render JSON data with 'tube' theme */}
                                <ReactJson src={carbonMocResponse} theme="ashes" style={{ overflow: "scroll", height: "275px" }} />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                padding: 2,
                                backgroundColor: 'rgb(228 226 226)',
                                borderRadius: 1,
                                minHeight: '200px',
                            }}
                        >
                            {/* Heading for the second column */}
                            <center>
                                <Typography variant="h5" sx={{ marginBottom: 2, fontSize: '1.8rem' }}>
                                    SQI - DB MOC Response
                                </Typography>
                            </center>
                            <br />

                            {/* Render JSON data with 'colors' theme */}
                            <ReactJson src={carbonAgentRes} theme="ashes" style={{ overflow: "scroll", height: "275px" }} />
                            <br />
                            {/* Buttons */}
                            <Box sx={{ marginTop: 2, display: 'flex', gap: 2 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        ':hover': { backgroundColor: '#1976d2' },
                                        padding: '10px 20px',
                                    }}
                                    // onClick={handlePostAction}
                                    onClick={() => setOpenInputJson(!openInputJson)}
                                >
                                    POST
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    sx={{
                                        ':hover': { backgroundColor: '#d32f2f' },
                                        padding: '10px 20px',
                                    }}
                                    onClick={handleDeleteAction}
                                >
                                    DELETE
                                </Button>
                            </Box><br /><br />
                            {openInputJson && <>
                                <TextField
                                    label="Enter JSON"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={9}
                                    value={jsonData}
                                    // onChange={handleInputChange}
                                    // error={!validJson}
                                    // helperText={error}
                                    sx={{
                                        width: "550px",
                                    }}
                                /> <br />
                                <Button
                                    variant="contained"
                                    onClick={handlePostAction}
                                    sx={{
                                        width: '200px',
                                        padding: '10px',
                                        backgroundColor: '#1976d2',
                                        '&:hover': { backgroundColor: '#1565c0' },
                                        marginTop: 2,
                                        marginLeft: "345px",
                                    }}
                                >
                                    Submit
                                </Button>
                            </>}
                        </Box>
                    </Grid>

                </Grid>
            }
            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="error">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCarbonEcom: (serviceId, accountUuid) => dispatch(getCarbonEcom(serviceId, accountUuid)),
        getCarbonAgent: (serviceId, accountUuid, customerId) => dispatch(getCarbonAgent(serviceId, accountUuid, customerId)),
        getCarbonMocResponse: (id) => dispatch(getCarbonMocResponse(id)),
        getSqiPayload: (payload) => dispatch(getSqiPayload(payload)),
        carbonAddBill: (payload) => dispatch(carbonAddBill(payload)),
        carbonDeleteBill: (id) => dispatch(carbonDeleteBill(id))
    };
};

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SqiCarbon);