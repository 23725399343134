import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import HeaderContent from '../../common/Header/HeaderContent';
import DataTilesFrame from "../../Data/accordion-frames/DataTilesFrame";
import TabBar from "../salesforceCreation/TapBar";
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountPopup from "./AccountPopup";
import { salesforceAccountNumber, salesforceCreation, salesforceUnmsValidation, salesforceSimValidation, salesforceUpdate } from "../../../actions/salesforceCreationAction";

const DataCreation = ({ history, env, salesforceAccountNumber, salesforceCreation, salesforceUnmsValidation, salesforceSimValidation, salesforceUpdate }) => {
    const [salesforceType, setSalesforce] = useState(null);
    const [dynamicCustomer, setDynamicCustomer] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(null);
    const [bvtType, setBvtType] = useState(null);
    const [accountNumber, setAccountNumber] = useState(null);

    /* Account Popup start */
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (salesforceType === "Data Creation") {
            handleClickOpen();
        }
    }, [salesforceType]);

    useEffect(() => {
        if (dialogStatus === "No") {
            handleClose();
        }
    }, [dialogStatus])

    const handleClose = () => {
        setOpen(false);
        setSalesforce(null);
    };
    /* Account Popup End */
    /* Tab start */
    const [currentTab, setCurrentTab] = useState(0);
    const [popupStatus, setPopupStatus] = useState(false);

    const tabOptions = [
        { name: 'customer', icon: <EmojiPeopleIcon /> },
        { name: 'contact', icon: <LocalPhoneIcon /> },
        { name: 'services', icon: <RoomServiceIcon /> }
    ];

    /* Tab end */

    // Popup Props
    const popupProps = {
        accountNumber,
        setAccountNumber,
        dynamicCustomer,
        setDynamicCustomer,
        open,
        handleClose,
        dialogStatus,
        setDialogStatus,
        setCurrentTab,
        setPopupStatus,
        setBvtType,
        setSalesforce,
        env,
        salesforceAccountNumber
    }

    const tabProps = {
        history,
        bvtType, setBvtType,
        tabs: tabOptions,
        tabUpdate: setCurrentTab,
        tabIndex: currentTab,
        dynamicCustomer: dynamicCustomer,
        setDynamicCustomer: setDynamicCustomer,
        setSalesforce,
        setDialogStatus,
        dialogStatus,
        setPopupStatus,
        setCurrentTab,
        salesforceCreation,
        salesforceUnmsValidation,
        salesforceSimValidation,
        salesforceUpdate,
        env,
        accountNumber,
    }

    const MainContent = () => {
        if (dialogStatus === "No" || popupStatus) {
            return <TabBar className={0} {...tabProps} />
        } return <DataTilesFrame
            color="turquoise"
            data={[
                {
                    label: 'Data Creation',
                    icon: "icon-sim-card"
                },
                {
                    label: 'Summary Page',
                    icon: "icon-search-find"
                }
            ]}
            select={(data) => {
                if (data?.label === "Summary Page") {
                    history.push("/data/utility/summaryPage")
                } else {
                    setSalesforce(data.label)
                }
            }}
            selected={salesforceType}
        />
    }

    return <div className="white" >
        <HeaderContent
            title={`Salesforce Data Creation`}
            icon="cogs ops-icon"
            empty={true}
            redirectUtility={true}
            push={history?.push}></HeaderContent>
        {MainContent()}
        {salesforceType === "Data Creation" && <AccountPopup {...popupProps} />}
    </div>
}

const mapStateToProps = (state) => {
    return {
        env: state?.auth?.selectedEnvValue
    };
};

const mapDispatchToProps = dispatch => {
    return {
        salesforceAccountNumber: (env, accountNumber) => dispatch(salesforceAccountNumber(env, accountNumber)),
        salesforceCreation: (env, payload) => dispatch(salesforceCreation(env, payload)),
        salesforceUnmsValidation: (payload, INT2) => dispatch(salesforceUnmsValidation(payload, INT2)),
        salesforceSimValidation: (payload) => dispatch(salesforceSimValidation(payload)),
        salesforceUpdate: (payload) => dispatch(salesforceUpdate(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataCreation);
