import React, { useEffect, useState } from "react";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import HeaderContent from '../../common/Header/HeaderContent';
import {
    AppBar,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Timeline from './DataSummaryTimeline';
import { salesforceDataSummary } from "../../../actions/salesforceCreationAction";
import { connect } from "react-redux";
import { displayErrorNew } from "../../common/alerts/alerts";
// import { useLocation } from 'react-router-dom';


const useStyles = makeStyles(() => ({
    bar: {
        minHeight: "60px",
        backgroundColor: "#ffffff",
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
    }
}));

const DataSummary = ({ history, salesforceDataSummary, salesforceCreation }) => {
    const classes = useStyles();
    const [requestID, setRequestID] = useState(null);
    const [summaryData, setSummaryData] = useState(null);
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');
    const [loading, setLoading] = useState(false);

    const resetForm = () => {
        setRequestID(null);
        setLoading(false);
        setHelperText('');
        setError(false);
        setSummaryData(null);
    }

    function handleBack() {
        history?.push("/data/utility/dataCreation")
    };

    const getDataSummary = (id = requestID) => {
        setLoading(true);
        setSummaryData(null);
        salesforceDataSummary({ requestID: requestID || id }).then((response) => {
            setLoading(false);
            if (response?.responseStatus === "Fail") {
                displayErrorNew({
                    title: "Fail",
                    errorMsg: response?.responseDescription,
                });
            }
            if (response?.status === 200) {
                setSummaryData(response?.data)
            }
        }).catch((error) => {
            setLoading(false);
        });
    }

    function getLastWord(str) {
        if(str){
            const words = str.split(' ');
            return words[words.length - 1]; // Get the last word
        }
    }

    useEffect(() => {
        if (salesforceCreation) {
            let salesforceCreationValue = getLastWord(salesforceCreation);
            setRequestID(salesforceCreationValue);
            getDataSummary(salesforceCreationValue);
        }
    }, [])

    return <>
        <div className="white" >
            <HeaderContent
                title={`Salesforce Data Summary`}
                icon="cogs ops-icon"
                empty={true}
                redirectUtility={true}
                push={history?.push}></HeaderContent>
            <AppBar elevation={0} position="static" color="default" className={`${classes.bar}`}>
                <TextField id="outlined-basic"
                    error={error}
                    helperText={helperText}
                    onChange={(e) => {
                        const inputValue = e.target.value;
                        // Check if the input is a number
                        if (/^\d*$/.test(inputValue)) {
                            setError(false);
                            setHelperText('');
                        } else {
                            setError(true);
                            setHelperText('Please enter a valid number');
                        }
                        setRequestID(inputValue);
                    }
                    }
                    label="Please Enter the Request ID" variant="outlined" sx={{
                        marginTop: "80px",
                        marginBottom: "10px",
                        width: "60%",
                        marginLeft: "120px",
                    }}
                    value={requestID || ''}
                />
                <Button disabled={!requestID || error} variant="contained" sx={{ width: "20%", marginLeft: "630px" }} onClick={getDataSummary}>Submit</Button>
                <Timeline data={summaryData} loading={loading} resetForm={resetForm} getDataSummary={getDataSummary} requestID={requestID}/>
            </AppBar> <br />
            <Button
                onClick={handleBack}
                variant="contained"
                color="primary"
            >
                Back
            </Button>
        </div>
    </>
}


const mapStateToProps = (state) => {
    return {
        salesforceCreation: state.salesforceSearch.salesforceCreation
    };
};

const mapDispatchToProps = dispatch => {
    return {
        salesforceDataSummary: (payload) => dispatch(salesforceDataSummary(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataSummary);
