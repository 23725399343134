import React, { useEffect, useState } from "react";
import { faker } from '@faker-js/faker';

import { connect } from "react-redux";
import HeaderContent from '../../common/Header/HeaderContent';
import DataTilesFrame from "../../Data/accordion-frames/DataTilesFrame";
import { Alert } from '@mui/material';
import moment from "moment";
var _ = require('lodash');
// import {  } from "../../../actions/salesforceSearchActions";
import ResidentialForm from "./ResidentialForm";
import { customerCreationAction } from "../../../actions/salesforceCreationAction";
faker.setLocale('en_AU');

const customerCreation = ({ history, env, customerCreationAction }) => {
    const salutationList = ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Dr'];
    const authorityList = ['Account Owner', 'Limited Authority', 'Third Party']
    function getRandomDateBefore19Years() {
        // Get the current date
        const currentDate = new Date();

        // Get the date exactly 19 years ago
        const nineteenYearsAgo = new Date();
        nineteenYearsAgo.setFullYear(currentDate.getFullYear() - 19);

        // Generate a random date before 19 years ago
        const randomTime = Math.random() * nineteenYearsAgo.getTime(); // Random time before 19 years ago

        // Create the random date
        const randomDate = new Date(randomTime);

        return randomDate;
    }
    function generateRandomText(length) {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var result = '';
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }
    function getRandomSelectValue(randomList) {
        const randomIndex = Math.floor(Math.random() * randomList.length);

        // Get a random title from the array
        const randomTitle = randomList[randomIndex];
        return randomTitle;
    }

    // Example usage
    const randomDate = getRandomDateBefore19Years();

    const [isReadOnly, setIsReadOnly] = useState(false);
    const [bvtType, setBvtType] = useState(null);
    const [account, setAccount] = useState(null);
    const [personalDataType, setPersonalDataType] = useState(null);

    // Form Field Values
    const [formValues, setFormValues] = useState({
        firstName: `TDM${faker.name.firstName()}`,
        middleName: '',
        lastName: bvtType === "BVT" ? 'TEST' : `TDM${faker.name.lastName()}`,
        birthdate: randomDate.toDateString(),
        email: `TDM.${generateRandomText(8)}${moment().format('DDMMHHmmss')}@mail.telstra-cave.com`,
        phoneNo: '+61428211933',
        salutation: getRandomSelectValue(salutationList),
        authority: 'Account Owner',
    });
    const [formErrors, setFormErrors] = useState({});

    const randomData = () => {
        let obj = {
            firstName: `TDM${faker.name.firstName()}`,
            middleName: '',
            lastName: bvtType === "BVT" ? 'TEST' : `TDM${faker.name.lastName()}`,
            birthdate: randomDate.toDateString(),
            email: `TDM.${generateRandomText(8)}${moment().format('DDMMHHmmss')}@mail.telstra-cave.com`,
            phoneNo: '+61428211933',
            salutation: getRandomSelectValue(salutationList),
            authority: 'Account Owner',
        }
        setFormValues(obj)
    }

    useEffect(() => {
        setAccount(null)
        setPersonalDataType(null)
        setFormErrors({})
    }, [bvtType])

    useEffect(() => {
        setPersonalDataType(null)
    }, [account])

    // Form Validation Start
    const validateField = (name, value) => {
        let errors = { ...formErrors };
        if (name) {
            switch (name) {
                case 'firstName':
                    errors.firstName = value ? '' : 'First Name is required';
                    break;
                case 'lastName':
                    errors.lastName = value ? '' : 'Last Name is required';
                    break;
                case 'birthdate':
                    const age = moment().diff(moment(value), 'years');
                    if (age < 18) {
                        errors.birthdate = 'You must be 18 years or older to proceed';
                    } else {
                        errors.birthdate = value ? '' : 'Birthdate is required';
                    }
                    break;
                case 'email':
                    const regex = /^TDM\.[a-zA-Z0-9]+@mail\.telstra-cave\.com$/i;
                    if (!value) {
                        errors.email = 'Email is required';
                    } else if (!regex.test(value)) {
                        errors.email = 'Please enter a valid email in the format: TDM.yourMailValue@mail.telstra-cave.com';
                    } else {
                        errors.email = '';
                    }
                    break;
                case 'phoneNo':
                    if (!value) {
                        errors.phoneNo = 'Phone Number is required';
                    } else if (!/^\d+$/.test(value)) {
                        errors.phoneNo = 'Phone Number must be a number';
                    } else {
                        errors.phoneNo = '';
                    }
                    break;
                case 'passportNumber':
                    if (!value) {
                        errors.passportNumber = 'Passport Number is required';
                    } else if (!/^[A-Z][0-9]{7}$/.test(value)) {
                        errors.passportNumber = 'Australian passport number must start with a letter followed by 7 digits. For Example.. (N1234567)';
                    } else {
                        errors.passportNumber = '';
                    }
                    break;
                default:
                    break;
            }
        } setFormErrors(errors);
    };


    const onChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        return validateField(name, value);
    }

    const handleDateChange = (name, date) => {
        setFormValues({ ...formValues, [name]: date });
        validateField(name, date);
    };

    let ResidentialFormProps = {
        isReadOnly,
        onChange,
        handleDateChange,
        formValues,
        formErrors,
        bvtType,
        validateField,
        randomData,
        salutationList,
        authorityList,
        customerCreationAction,
        env,
        setPersonalDataType,
        randomData
    }

    useEffect(() => {
        if (bvtType === "BVT") {
            setIsReadOnly(true);
        }
    }, [bvtType])

    const MainContent = () => {
        return <>
            <DataTilesFrame
                heading="Please choose the BVT type"
                color="turquoise"
                data={[
                    {
                        label: 'BVT',
                        icon: "icon-sim-card"
                    },
                    {
                        label: 'NON-BVT',
                        icon: "icon-search-find"
                    }
                ]}
                select={(data) => setBvtType(data.label)}
                selected={bvtType}

            />
            {bvtType && <DataTilesFrame
                heading="Please choose your Account"
                color="blue"
                data={[
                    {
                        label: 'Personal',
                        icon: "icon-my-profile-tick-presence"
                    },
                    {
                        label: 'Business',
                        icon: "icon-profitable"
                    }
                ]}
                select={(data) => setAccount(data.label)}
                selected={account}
            />}
            {account === "Personal" &&
                <DataTilesFrame
                    heading="Please choose your Customer Type"
                    color="blue"
                    data={[
                        {
                            label: 'Residential',
                            icon: "icon-profitable"
                        }
                    ]}
                    select={(data) => setPersonalDataType(data.label)}
                    selected={personalDataType}
                />}
            {account === "Business" &&
                <Alert variant="outlined" severity="info" style={{
                    "width": "350px",
                    "marginLeft": "200px",
                    "paddingLeft": "100px"
                }}>
                    Coming Soon
                </Alert>
            }
            {account === "Personal" && personalDataType === "Residential" && <ResidentialForm {...ResidentialFormProps} />}
        </>
    }

    return <div className="white" >
        <HeaderContent
            title={`B2C Customer Creation`}
            icon="cogs ops-icon"
            empty={true}
            redirectUtility={true}
            push={history?.push}></HeaderContent>
        {MainContent()}
    </div>
}

const mapStateToProps = (state) => {
    return {
        env: state?.auth?.selectedEnvValue
    };
};

const mapDispatchToProps = dispatch => {
    return {
        customerCreationAction: (payload, env) => dispatch(customerCreationAction(payload, env)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(customerCreation);
